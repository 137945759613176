import Navbar from "./Navbar";
import "./OurTeam.css";
import border from "../Assets/yellow-1210520_1280.png";
import raju from "../Assets/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg";
import OurTeamProfile from "./OurTeamProfile";
// import Footer from "./Footer/Footer";

// import { Application } from '@splinetool/runtime';

// const canvas = document.getElementById('canvas3d');
// const app = new Application(canvas);
// app.load('https://prod.spline.design/apok-Z5b6LvyNojx/scene.splinecode');

const OurTeam = () => {
  const handleButtonClick = (sectionId) => {
    document.getElementById("Overall-org").style.display = "none";
    document.getElementById("Tech-org").style.display = "none";
    document.getElementById("Sports-org").style.display = "none";
    document.getElementById("Media-org").style.display = "none";
    document.getElementById("Cult-org").style.display = "none";
    // document.getElementById("Ops-org").style.display = "none";
    document.getElementById("Fin-org").style.display = "none";

    document.getElementById("Overall-org-button").style.display = "none";
    document.getElementById("Tech-org-button").style.display = "none";
    document.getElementById("Sports-org-button").style.display = "none";
    document.getElementById("Media-org-button").style.display = "none";
    document.getElementById("Cult-org-button").style.display = "none";
    // document.getElementById("Ops-org-button").style.display = "none";
    document.getElementById("Fin-org-button").style.display = "none";

    document.getElementById(`${sectionId}-button`).style.display = "flex";
    document.getElementById(sectionId).style.display = "flex";
    document.getElementById("back-button").style.display = "flex";
  };
  const handleBackClick = () => {
    document.getElementById("Overall-org").style.display = "none";
    document.getElementById("Tech-org").style.display = "none";
    document.getElementById("Sports-org").style.display = "none";
    document.getElementById("Media-org").style.display = "none";
    document.getElementById("Cult-org").style.display = "none";
    // document.getElementById("Ops-org").style.display = "none";
    document.getElementById("Fin-org").style.display = "none";

    document.getElementById("Overall-org-button").style.display = "flex";
    document.getElementById("Tech-org-button").style.display = "flex";
    document.getElementById("Sports-org-button").style.display = "flex";
    document.getElementById("Media-org-button").style.display = "flex";
    document.getElementById("Cult-org-button").style.display = "flex";
    // document.getElementById("Ops-org-button").style.display = "flex";
    document.getElementById("Fin-org-button").style.display = "flex";

    document.getElementById("back-button").style.display = "none";
  };
  return (
    <>
    <div className="Our-Team">
      <Navbar></Navbar>
      <h1 className="center">Our Team</h1>
      <h2 id="back-button" onClick={() => handleBackClick()}>
        <i class="fa fa-angle-double-left" aria-hidden="true"></i> Back
      </h2>

      <div className="Our-Team-Menu">
        <div className="Our-Team-Menu-Item-div"  onClick={() => handleButtonClick("Overall-org")}             id="Overall-org-button">
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
          >
            Overall
          </h2>
        </div>
        <div className="Our-Team-Menu-Item-div"    onClick={() => handleButtonClick("Tech-org")}             id="Tech-org-button">
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
         
          >
            Tech Team
          </h2>
        </div>
        <div className="Our-Team-Menu-Item-div"             onClick={() => handleButtonClick("Sports-org")}             id="Sports-org-button"
>
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
          >
            Sports team
          </h2>
        </div>
        <div className="Our-Team-Menu-Item-div"             onClick={() => handleButtonClick("Media-org")}             id="Media-org-button"
>
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
          >
            Media
          </h2>
        </div>
        <div className="Our-Team-Menu-Item-div"             onClick={() => handleButtonClick("Cult-org")}             id="Cult-org-button"
>
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
          >
            Cultural
          </h2>
        </div>
        {/* <div className="Our-Team-Menu-Item-div"             onClick={() => handleButtonClick("Ops-org")}             id="Ops-org-button"
>
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
          >
            Operation and others
          </h2>
        </div> */}
        <div className="Our-Team-Menu-Item-div"             onClick={() => handleButtonClick("Fin-org")}             id="Fin-org-button"
>
          <img className="profileborder" src={border}></img>
          <h2
            className="Our-Team-Menu-Item"
          >
            Finance and sponsorhip
          </h2>
        </div>
      </div>
      <div cl></div>

      {/* <h2 className="center">Overall Coordinators</h2> */}
      <div className="Organisers" id="Overall-org">
        <OurTeamProfile
          Name="Sheetal Jain"
          position="President"
          profileimg="https://i.postimg.cc/02JGgRVz/20-EEE1031-Sheetal-Jain.jpg"
          linkedin="https://www.linkedin.com/in/sheetal-jain-7ba509272?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:sheetaljain@nitgoa.ac.in"
          insta="https://www.instagram.com/sheetal._.jain18?igsh=MTRucWwyZHZmb2hzNw=="
        ></OurTeamProfile>
                <OurTeamProfile
          Name="Divyarajsinh R Solanki"
          position="General Secretary"
          profileimg="https://i.postimg.cc/BZHrPnJV/Divyaraj-Solanki.png"
          linkedin="https://www.instagram.com/abnormal_being0016?igsh=ZGNjOWZkYTE3MQ=="
          email="mailto:divyarajrsolanki9@nitgoa.ac.in"
          insta=""
        ></OurTeamProfile>
        <OurTeamProfile
          Name="Ayush Singh"
          position="Prasang Head"
          profileimg="https://i.postimg.cc/P5yy1Pmx/20-EEE1038-Ayush-singh.jpg"
          linkedin="https://www.linkedin.com/in/ayush-singh-7b5b14213"
          email="mailto:ayushsingh@nitgoa.ac.in"
          insta="https://www.instagram.com/ayushsingh_001_?igsh=ZDZncDBwdDIxOTNx&utm_source=qr"
        ></OurTeamProfile>
                        <OurTeamProfile
          Name="Kaushik Prabhudessai "
          position="Treasurer"
          profileimg="https://i.postimg.cc/SK1mx31y/Kaushik-Prabhudesai.jpg"
          linkedin="https://www.linkedin.com/in/kaushik-prabhudessai-28452327a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          email="mailto:kaushik.prabhudessai16@nitgoa.ac.in"
          insta="https://www.instagram.com/kau.sickk?igsh=YzVkODRmOTdmMw%3D%3D&utm_source=qr"
        />
        <OurTeamProfile
          Name="Santosh Mourya"
          position="Technical head"
          profileimg="https://i.postimg.cc/pdwdR31g/Santosh-Mourya.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
                <OurTeamProfile
          Name="Shreya Gupta"
          position="Cultural"
          profileimg="https://i.postimg.cc/gj7wV2Kr/Shreya-Gupta.png"
          linkedin="https://www.linkedin.com/in/shreya-gupta-345092224"
          email="mailto:shreya.291003@nitgoa.ac.in"
          insta="https://www.instagram.com/_shreyagupta.__?igsh=YzVkODRmOTdmMw=="
        />
                <OurTeamProfile
          Name="Dipanshu Surolia"
          position="Sports head"
          profileimg="https://i.postimg.cc/yNR6NVL8/IMG-20240207-210944-1.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Ridhi D Kumar"
          position="Media"
          profileimg="https://i.postimg.cc/DyGd8pB7/Whats-App-Image-2024-02-07-at-22-42-53.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
        <OurTeamProfile
          Name="Binit Mishra"
          position="Media"
          profileimg="https://i.postimg.cc/cJbJjGqW/Whats-App-Image-2024-02-07-at-21-03-37.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
                {/* <OurTeamProfile
          Name="Raj Rawariya"
          position="Website Head"
          profileimg="https://i.postimg.cc/Yqq9Gvp8/photo-2024-02-07-22-51-25.jpg"
          linkedin="https://www.linkedin.com/in/raj-rawariya/"
          email="mailto:ravariyaraj@nitgoa.ac.in"
          insta="https://www.instagram.com/raj_ravariya_/"
        /> */}
      </div>

      <div className="Organisers" id="Cult-org">
        <OurTeamProfile
          Name="Shreya Gupta"
          position="Cultural Head, Duet and Duet Singing Head"
          profileimg="https://i.postimg.cc/gj7wV2Kr/Shreya-Gupta.png"
          linkedin="https://www.linkedin.com/in/shreya-gupta-345092224"
          email="mailto:shreya.291003@nitgoa.ac.in"
          insta="https://www.instagram.com/_shreyagupta.__?igsh=YzVkODRmOTdmMw=="
        />
                <OurTeamProfile
          Name="Anjali Yadav"
          position="Group Dance Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
          <OurTeamProfile
            Name="Preethi Gudimallam"
            position="Group Dance Cohead"
            profileimg="https://i.postimg.cc/tJq8HF8n/Preethi-Gudimallam.jpg"
            linkedin="https://www.linkedin.com/in/preethi-gudimallam-78a366216?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            email=""
          />
                  <OurTeamProfile
          Name="Arpita Sukhwal"
          position="Duet Dance Head , Fashion Show Cohead "
          profileimg="https://i.postimg.cc/2SY3Zpkm/Arpita-Sukhwal.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
                <OurTeamProfile
          Name="Gunaditya Patil"
          position="Duet CoHead"
          profileimg="https://i.postimg.cc/vTF6v2xX/Good-Photo-21-EEE1013-Gunaditya-Patil.jpg"
          linkedin="http://www.linkedin.com/in/gunaditya-patil"
          email="mailto:gunaditya.v.p@nitgoa.ac.in"
          insta="http://www.instagram.com/_irrelevantly_relevant_"
        />
                <OurTeamProfile
          Name="Sheetal Jain"
          position="Solo Dance Head, Instruments(head)"
          profileimg="https://i.postimg.cc/02JGgRVz/20-EEE1031-Sheetal-Jain.jpg"
          linkedin="https://www.linkedin.com/in/sheetal-jain-7ba509272?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:sheetaljain@nitgoa.ac.in"
          insta="https://www.instagram.com/sheetal._.jain18?igsh=MTRucWwyZHZmb2hzNw=="
        ></OurTeamProfile>
                <OurTeamProfile
          Name="Vaishnavi Kshatriya"
          position="Duet and Duet Singing Head, Fashion Show Head "
          profileimg="https://i.postimg.cc/KYYDDRPD/Vaishnavi.jpg"
          insta="https://www.instagram.com/_vaishnavi_kshatriya_?igsh=MWMyYmlwdHFqZHY2Zw=="
          linkedin="https://www.linkedin.com/in/vaishnavi-kshatriya/"
          email="mailto:Vaishnavikshatriya@nitgoa.ac.in"
        />
        {/* <OurTeamProfile
          Name="Amber Khan"
          position=""
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
        <OurTeamProfile
          Name="Het parmar"
          position=""
          profileimg="https://i.postimg.cc/hvQwxvWN/Screenshot-2023-1227-081935-PARMAR-HET-VINAY-NITGoa.jpg"
          linkedin="https://www.linkedin.com/in/het-parmar-8369722a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:hetparmar1582004@nitgoa.ac.in"
          insta="https://www.instagram.com/hetparmar1?igsh=OGQ5ZDc2ODk2ZA=="
        ></OurTeamProfile> */}
        <OurTeamProfile
          Name="Riddhi Priolkar"
          position="Instrument Cohead"
          profileimg="https://i.postimg.cc/DfqRCRQ8/Riddhi-Priolkar.jpg"
          linkedin="https://www.linkedin.com/in/riddhi-sinai-priolkar-9575a8244"
          email="mailto:ridpriolkar@nitgoa.ac.in"
          insta="https://www.instagram.com/rid.priolkar?igsh=ajI0Ync2Yjc3N2No"
        ></OurTeamProfile>
                <OurTeamProfile
          Name="Piyush Singh"
          position="Skit Head, Stand up(head)"
          profileimg="https://i.postimg.cc/yYBV9s3r/IMG-20240128-183430.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
            <OurTeamProfile
          Name="Komal Thakur"
          position="Skit Cohead"
          profileimg="https://i.postimg.cc/MTBxP0V7/Thakur-Komal.jpg"
          linkedin="https://in.linkedin.com/in/komal-thakur-5486b82a4"
          email="mailto:thakurkomal132@nitgoa.ac.in "
          insta="https://www.instagram.com/itsmekomalthakur?igsh=OGQ5ZDc2ODk2ZA=="
        />
                <OurTeamProfile
          Name="Sri Rekha"
          position="Face painting, head"
          profileimg="https://i.postimg.cc/mgkjWmkG/Sri-Rekha.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Meera R"
          position="Face Painting Cohead"
          profileimg="https://i.postimg.cc/qvZcNfyD/P-20190908-193426-MEERA-R-NITGoa.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
        {/* 
        <OurTeamProfile
          Name="Nita Tulsidas"
          position="The Vocal Nirvana Cohead"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        /> */}
        {/* <OurTeamProfile
          Name="Gourang Gawade"
          position="Nukkad Natak Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Kedarnath Phadte"
          position="Nukkad Natak Cohead"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Ujwal"
          position="Jokes Jubilation Head"
          profileimg="https://i.postimg.cc/2SqzLfy9/Ujwal-Gahukar.jpg"
          linkedin="https://www.linkedin.com/in/ujwal-gahukar-18177b250?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:ujwgahukar45@nitgoa.ac.in"
          insta="https://www.instagram.com/ujwal.gahukar?igsh=OGQ5ZDc2ODk2ZA=="
        />
        <OurTeamProfile
          Name="Yashraj"
          position="Jokes Jubilation Cohead"
          profileimg="https://i.postimg.cc/3NnvNHNJ/Yashraj-Bhavsar.jpg"
          linkedin="https://www.linkedin.com/in/yashraj-bhavsar-113833275?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:yashrajbhavsar7@nitgoa.ac.in"
          insta="https://www.instagram.com/yashraj_bhavsar_?igsh=MXg2YzBpdW51MWN5ZQ=="
        />
        <OurTeamProfile
          Name="Aryan Chari"
          position="Rainbow Palette Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        /> */}
        {/* <OurTeamProfile
          Name="Krity"
          position="Rainbow Palette Cohead"
          profileimg="https://i.postimg.cc/dQ58B8Qk/Krity-Rao.jpg"
          linkedin="https://www.linkedin.com/in/krity-rao-45183624b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:krity181103@nitgoa.ac.in"
          insta="https://www.instagram.com/krity1811?igsh=OGQ5ZDc2ODk2ZA=="
        />

        <OurTeamProfile
          Name="Nidhi Kumari"
          position="The Retail Therapy Cohead"
          profileimg="https://i.postimg.cc/9f0jK1ms/Nidhi.jpg"
          linkedin="http://linkedin.com/in/nidhi-581898295"
          insta="https://www.instagram.com/ni_dhi6749?igsh=NGVhN2U2NjQ0Yg=="
          email="mailto:nidhikumari4022003@nitgoa.ac.in"
        /> */}
      </div>

      <div className="Organisers" id="Tech-org">
      <OurTeamProfile
          Name="Santosh Mourya"
          position="Technical Head"
          profileimg="https://i.postimg.cc/pdwdR31g/Santosh-Mourya.jpg"
          linkedin="https://www.linkedin.com/in/santosh-mourya-b38389229"
          email="mailto:"
          insta="https://www.linkedin.com/in/santosh-mourya-b38389229"
        ></OurTeamProfile>
        <OurTeamProfile
          Name="Aditya Srekar"
          position="Tech Advisor"
          profileimg="https://i.postimg.cc/bNDX3wT8/Aditya-Srekar.jpg"
          linkedin="https://www.linkedin.com/in/aditya-srekar"
          email="mailto:"
          insta="https://www.instagram.com/aditya_srekar"
        />
                <OurTeamProfile
          Name="Ankit Singh"
          position="Quiz Head"
          profileimg="https://i.postimg.cc/fLbG0cT7/Ankit-singh.jpg"
          linkedin="https://www.linkedin.com/in/ankit5ingh"
          email="mailto:"
          insta="https://www.instagram.com/ankits1"
        />
                <OurTeamProfile
          Name="Rounak Soni"
          position="Quiz Cohead"
          profileimg="https://i.postimg.cc/3JSNVBzp/Rounak-Soni.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Divyarajsinh R Solanki"
          position="Surprise Event Head"
          profileimg="https://i.postimg.cc/BZHrPnJV/Divyaraj-Solanki.png"
          linkedin="https://www.instagram.com/abnormal_being0016?igsh=ZGNjOWZkYTE3MQ=="
          email="mailto:divyarajrsolanki9@nitgoa.ac.in"
          insta=""
        ></OurTeamProfile>
                        <OurTeamProfile
          Name="Avanish Gaon"
          position="Co-head (surprise event)"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
                <OurTeamProfile
          Name="Ayush Singh"
          position="Treasure Hunt Head"
          profileimg="https://i.postimg.cc/P5yy1Pmx/20-EEE1038-Ayush-singh.jpg"
          linkedin="https://www.linkedin.com/in/ayush-singh-7b5b14213"
          email="mailto:ayushsingh@nitgoa.ac.in"
          insta="https://www.instagram.com/ayushsingh_001_?igsh=ZDZncDBwdDIxOTNx&utm_source=qr"
        />
                <OurTeamProfile
          Name="Gunaditya Patil"
          position="Treasure Hunt Cohead"
          profileimg="https://i.postimg.cc/vTF6v2xX/Good-Photo-21-EEE1013-Gunaditya-Patil.jpg"
          linkedin="http://www.linkedin.com/in/gunaditya-patil"
          email="mailto:gunaditya.v.p@nitgoa.ac.in"
          insta="http://www.instagram.com/_irrelevantly_relevant_"
        />

        {/* <OurTeamProfile
          Name="Shivam Pal"
          position="Technical Manager"
          profileimg="https://i.postimg.cc/Bvvdy598/Shivam-Pal.jpg"
          linkedin="https://www.linkedin.com/in/shivam-p-8aa44b229"
          email="mailto:"
          insta="https://www.instagram.com/shivam____pal?igsh=MmVlMjlkMTBhMg=="
        /> */}

        {/* <OurTeamProfile
          Name="Komal Thakur"
          position=""
          profileimg="https://i.postimg.cc/MTBxP0V7/Thakur-Komal.jpg"
          linkedin="https://in.linkedin.com/in/komal-thakur-5486b82a4"
          email="mailto:thakurkomal132@nitgoa.ac.in "
          insta="https://www.instagram.com/itsmekomalthakur?igsh=OGQ5ZDc2ODk2ZA=="
        />
        <OurTeamProfile
          Name="Veenol Naik,"
          position=""
          profileimg="https://i.postimg.cc/htRCTLtF/Veenol-Naik.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Ramesh Choudhary"
          position=""
          profileimg="https://i.postimg.cc/xChg6MnS/Ramesh-Choudhary.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Nidhi kumari"
          position=""
          profileimg="https://i.postimg.cc/9f0jK1ms/Nidhi.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Ashrita"
          position=""
          profileimg="https://i.postimg.cc/nrjbrLj2/Ashrita.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Tejdeep"
          position=""
          profileimg="https://i.postimg.cc/SRPrnq2f/Tej-Deep-Maddina.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Shreya Gupta"
          position=""
          profileimg="https://i.postimg.cc/gj7wV2Kr/Shreya-Gupta.png"
          linkedin="https://www.linkedin.com/in/shreya-gupta-345092224"
          email="mailto:shreya.291003@nitgoa.ac.in"
          insta="https://www.instagram.com/_shreyagupta.__?igsh=YzVkODRmOTdmMw=="
        ></OurTeamProfile>
        <OurTeamProfile
          Name="Arpita Sukhwal"
          position=""
          profileimg="https://i.postimg.cc/2SY3Zpkm/Arpita-Sukhwal.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
    */}
        <OurTeamProfile
          Name="Raj Rawariya"
          position="Website Head"
          profileimg="https://i.postimg.cc/sxddBrYR/Raj.png"
          linkedin="https://www.linkedin.com/in/raj-rawariya/"
          email="mailto:ravariyaraj@nitgoa.ac.in"
          insta="https://www.instagram.com/raj_ravariya_/"
        />
        <OurTeamProfile
          Name="Harsh khandelwal"
          position="Website Cohead"
          profileimg="https://i.postimg.cc/6QJs3wtL/Harsh-Khandelwal.png"
          linkedin=""
          email="mailto:"
          insta=""
        />
      </div>

      <div className="Organisers" id="Media-org">
      <OurTeamProfile
          Name="Panchami Pai"
          position="Publicity and Design Head "
          profileimg="https://i.postimg.cc/Hxyky1L9/Panchami-Pai.jpg"
          linkedin="https://www.linkedin.com/in/panchami-pai-802984224?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:"
          insta="https://www.instagram.com/punch_a_meee?igsh=OGQ5ZDc2ODk2ZA==https://www.linkedin.com/in/veenol-naik"
        />
        <OurTeamProfile
          Name="Binit Mishra"
          position="Photography Head and Design Head"
          profileimg="https://i.postimg.cc/cJbJjGqW/Whats-App-Image-2024-02-07-at-21-03-37.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
                <OurTeamProfile
          Name="Ridhi D Kumar"
          position=" Design Head Digital Art Head,Photography CoHead"
          profileimg="https://i.postimg.cc/FKW6Ft2R/Whats-App-Image-2024-02-07-at-22-38-47.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
              <OurTeamProfile
          Name="Arpita Sukhwal"
          position="Publicity and Design Head "
          profileimg="https://i.postimg.cc/2SY3Zpkm/Arpita-Sukhwal.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Meera R"
          position="Design Cohead"
          profileimg="https://i.postimg.cc/qvZcNfyD/P-20190908-193426-MEERA-R-NITGoa.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile>
        <OurTeamProfile
          Name="PC jony Mahith"
          position="Pictures Credit"
          profileimg="https://i.postimg.cc/RZs00Mz1/Whats-App-Image-2024-02-09-at-20-22-08.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        /> 
      </div>

      <div className="Organisers " id="Sports-org">
      <OurTeamProfile
          Name="Dipanshu Surolia"
          position="Sports head"
          profileimg="https://i.postimg.cc/yNR6NVL8/IMG-20240207-210944-1.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
                    <OurTeamProfile
          Name="Komal Thakur"
          position="Binge Eating Head"
          profileimg="https://i.postimg.cc/MTBxP0V7/Thakur-Komal.jpg"
          linkedin="https://in.linkedin.com/in/komal-thakur-5486b82a4"
          email="mailto:thakurkomal132@nitgoa.ac.in "
          insta="https://www.instagram.com/itsmekomalthakur?igsh=OGQ5ZDc2ODk2ZA=="
        />
                <OurTeamProfile
          Name="Vijay"
          position="Game of Tag Head"
          profileimg="https://i.postimg.cc/T200tnQC/Whats-App-Image-2024-02-14-at-21-21-23.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        {/* <OurTeamProfile
          Name="Gowtham B."
          position="Box Cricket Head"
          profileimg="https://i.postimg.cc/CMsvVBhS/Gowtham.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        ></OurTeamProfile> */}
        {/* <OurTeamProfile
          Name="Sri Rekha"
          position="Box Cricket Cohead"
          profileimg="https://i.postimg.cc/mgkjWmkG/Sri-Rekha.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        /> */}
        <OurTeamProfile
          Name="Praveen Kumar Singh"
          position="5 Men Race Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
                <OurTeamProfile
          Name="Mehul Rathod"
          position="Tug of War & BGMI Head"
          profileimg="https://i.postimg.cc/25X1Wx7r/Mehulkumar-Rathod.jpg"
          linkedin="https://www.linkedin.com/in/mehul-rathod-b5091a235"
          email="mailto:rathodmehulkumarpratap@nitgoa.ac.in"
          insta="https://www.instagram.com/m_e_h_u_l_02?igsh=dHZvYjYyMjB1bjFn"
        />
                <OurTeamProfile
          Name="Santosh Mourya"
          position="Box Cricket Head"
          profileimg="https://i.postimg.cc/pdwdR31g/Santosh-Mourya.jpg"
          linkedin="https://www.linkedin.com/in/santosh-mourya-b38389229"
          email="mailto:"
          insta=""
        />
        {/* <OurTeamProfile
          Name="Krity Rao"
          position="Game of TAG Cohead"
          profileimg="https://i.postimg.cc/dQ58B8Qk/Krity-Rao.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Meghna"
          position="Game of TAG Cohead"
          profileimg="https://i.postimg.cc/FKhR8BD9/Meghana-Pathipati.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        /> */}


        {/* <OurTeamProfile
          Name="Rakeesh Sanikommu"
          position="Tug of War Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Yash Patil"
          position="Tug of War Cohead"
          profileimg="https://i.postimg.cc/JzSzPFJY/Yash-Patil.jpg"
          linkedin=""
          email="mailto:"
          insta="https://www.instagram.com/yashpatil_115?igsh=MTNxaHZ3c3hpcHpicA=="
        />
        <OurTeamProfile
          Name="Surya Teja"
          position="FIFA Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Harsh Khandelwal"
          position="FIFA Cohead"
          profileimg="https://i.postimg.cc/4XnXWN0M/Harsh-Khandelwal.png"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Sumit Jakhar"
          position="Valorant Head"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Parveen Kumar"
          position="Valorant Cohead"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Utkarsh Kumar"
          position="BGMI Head"
          profileimg="https://i.postimg.cc/cHHww2N9/Utkarsh-Kumar.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        /> */}

      </div>

      {/* <div className="Organisers" id="Ops-org">
        <OurTeamProfile
          Name="Santosh Mourya"
          position="Management and support"
          profileimg="https://i.postimg.cc/pdwdR31g/Santosh-Mourya.jpg"
          linkedin="https://www.linkedin.com/in/santosh-mourya-b38389229"
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Panchami Pai "
          position="Core and Design"
          profileimg="https://i.postimg.cc/Hxyky1L9/Panchami-Pai.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
      </div> */}
      <div className="Organisers" id="Fin-org">
        <OurTeamProfile
          Name="Kaushik Prabhudessai "
          position="Treasurer"
          profileimg="https://i.postimg.cc/SK1mx31y/Kaushik-Prabhudesai.jpg"
          linkedin="https://www.linkedin.com/in/kaushik-prabhudessai-28452327a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          email="mailto:kaushik.prabhudessai16@nitgoa.ac.in"
          insta="https://www.instagram.com/kau.sickk?igsh=YzVkODRmOTdmMw%3D%3D&utm_source=qr"
        />
             <OurTeamProfile
          Name="Maaz Ali Mattehalli"
          position="Finance Head"
          profileimg="https://i.postimg.cc/Vv5npxS2/Maaz.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Ayush Singh"
          position="Finance Head"
          profileimg="https://i.postimg.cc/P5yy1Pmx/20-EEE1038-Ayush-singh.jpg"
          linkedin="https://www.linkedin.com/in/ayush-singh-7b5b14213"
          email="mailto:ayushsingh@nitgoa.ac.in"
          insta="https://www.instagram.com/ayushsingh_001_?igsh=ZDZncDBwdDIxOTNx&utm_source=qr"
        />
             <OurTeamProfile
          Name="Sheetal Jain"
          position="Finance Head"
          profileimg="https://i.postimg.cc/02JGgRVz/20-EEE1031-Sheetal-Jain.jpg"
          linkedin="https://www.linkedin.com/in/sheetal-jain-7ba509272?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:sheetaljain@nitgoa.ac.in"
          insta="https://www.instagram.com/sheetal._.jain18?igsh=MTRucWwyZHZmb2hzNw=="
        ></OurTeamProfile>
              <OurTeamProfile
          Name="Santosh Mourya"
          position=" Sponsorship Head"
          profileimg="https://i.postimg.cc/pdwdR31g/Santosh-Mourya.jpg"
          linkedin="https://www.linkedin.com/in/santosh-mourya-b38389229"
          email="mailto:"
          insta="https://www.linkedin.com/in/santosh-mourya-b38389229"
        ></OurTeamProfile>
          <OurTeamProfile
          Name="Vijay"
          position="Finance Coordinator"
          profileimg="https://i.postimg.cc/T200tnQC/Whats-App-Image-2024-02-14-at-21-21-23.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        {/* <OurTeamProfile
          Name="Krity Rao"
          position="Sponsorship"
          profileimg="https://i.postimg.cc/dQ58B8Qk/Krity-Rao.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Dharavath Pavan "
          position="Sponsorship"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Gaurav Patil"
          position="Sponsorship"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Gaurang Gawade"
          position="Sponsorship"
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Binit Mishra"
          position="Sponsorship"
          profileimg="https://i.postimg.cc/kgqqgt8s/Binit-Mishra.jpg"
          linkedin="https://www.linkedin.com/in/binit-mishra-61b4a3216?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          email="mailto:binitmishra512@nitgoa.ac.in"
          insta="https://www.instagram.com/biniiiiit?igsh=OGQ5ZDc2ODk2ZA=="
        />
        <OurTeamProfile
          Name="Veenol Naik"
          position="Sponsorship"
          profileimg="https://i.postimg.cc/htRCTLtF/Veenol-Naik.jpg"
          linkedin="https://www.linkedin.com/in/veenol-naik"
          email="mailto:"
          insta="https://www.instagram.com/punch_a_meee?igsh=OGQ5ZDc2ODk2ZA=="
        />
        <OurTeamProfile
          Name="Yash Patil"
          position="Sponsorship"
          profileimg="https://i.postimg.cc/JzSzPFJY/Yash-Patil.jpg"
          linkedin=""
          email="mailto:"
          insta=""
        />
        <OurTeamProfile
          Name="Sudhanshu"
          position="Sponsorship "
          profileimg={raju}
          linkedin=""
          email="mailto:"
          insta=""
        /> */}
      </div>

            {/* <div className="Media"></div>
      <div className="Technical and Website"></div>
      <div className="Technical and Website"></div>
      <div className="Technical and Website"></div> */}
    </div>
    <div className="bottom">
          <p>Made with ❤️ Team Prasang</p>
        </div>
    </>
  );
};

export default OurTeam;
