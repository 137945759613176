import React, { useEffect } from "react";
import "./FAQs.css";

const FAQs = () => {
  useEffect(() => {
    const faqs = document.querySelectorAll(".faq");

    faqs.forEach((faq) => {
      const question = faq.querySelector(".question");

      const clickHandler = () => {
        faq.classList.toggle("active-faq");
      };

      question.addEventListener("click", clickHandler);

      return () => {
        question.removeEventListener("click", clickHandler);
      };
    });
  }, []); 

  return (
    <div className="Faqs">
      <h1 className="center">Frequently Asked Questions</h1>

      {/* <div className="faq" data-aos="fade-up">
        <div className="question">
          <h3>Who can participate?</h3>
          <svg width="15" height="10" viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              strokeWidth="7"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
          Anyone! irrespective of any branch.
          </p>
        </div>
      </div> */}
            <div className="faq" data-aos="fade-up">
        <div className="question">
          <h3>Who can participate?</h3>
          <svg width="15" height="10" viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              strokeWidth="7"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
          Anyone! irrespective of branch.
          </p>
        </div>
      </div>

      {/* <div className="faq" data-aos="fade-up">
        <div className="question">
          <h3>What is the registration procedure?</h3>
          <svg width="15" height="10" viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              strokeWidth="7"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
            Register online on the website fill in all the details process the payment(if any) done! you are registered, See you in Prasang!
          </p>
        </div>
      </div>

      <div className="faq" data-aos="fade-up">
        <div className="question">
          <h3>What is the refund procedure?</h3>
          <svg width="15" height="10" viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              strokeWidth="7"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
             No refund policy.But in any extreme situation if any Event/Workshop gets cancelled participants will receive 100% refund.
          </p>
        </div>
      </div>

      <div className="faq" data-aos="fade-up">
        <div className="question">
          <h3>What is the registration deadline?</h3>
          <svg width="15" height="10" viewBox="0 0 42 25">
            <path
              d="M3 3L21 21L39 3"
              stroke="white"
              strokeWidth="7"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="answer">
          <p>
             By 23rd Jan all registration will close. But it is recommended to book your slot before hand to avoid any last minute problem.
          </p>
        </div>
      </div> */}

       
    </div>
  );
};

export default FAQs;
