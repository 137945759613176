import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="row">
        <div className="col">
          <h3>Contact Us</h3>
          <a href="mailto:tesla@nitgoa.ac.in">
            <i className="fa fa-envelope" aria-hidden="true"></i>
            tesla@nitgoa.ac.in
          </a>
          <h3>Follow Us</h3>
          <ul className="social">
            <li>
              <a href="https://www.instagram.com/tesla_nitg/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/teslanitg/">
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="col">
          <h3>Reach Us</h3>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246309.88211125284!2d73.82954931685201!3d15.290034950873295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfba54fee1f1cd%3A0xea0a4948645fa299!2sNational%20Institute%20of%20Technology%20Goa!5e0!3m2!1sen!2sin!4v1707351611108!5m2!1sen!2sin"
            width="400"
            height="300"
            
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */}
          <iframe
             title="Nit Goa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3850.827523451066!2d74.0080527751171!3d15.16781396293823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbe4dade7a19b97%3A0x9db46cb6ea1d0d3f!2sNIT%20Goa%20Permanent%20Campus!5e0!3m2!1sen!2sin!4v1707560044677!5m2!1sen!2sin"
            width="400"
            height="300"
            allowfullscreen=""
            loading="lazy"
            style={{ border: "2px solid #ffcd00" }}
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="bottom">
        <p>Made with ❤️ Team Prasang</p>
      </div>
    </footer>
  );
};

export default Footer;
