import React from "react";
// import AOS from "aos";
import "aos/dist/aos";
// import { useEffect } from "react";
// import codeCombatImg from "../Assets/Code Combat.png";
import EventBox from "./EventBox";
import "./Competition.css"
import ElectricButton from "./ElectricButton";
// import ardunonova from "../Assets/sahand-babali-gavODTHG36Y-unsplash.jpg"
import GreenTick from "../Assets/Green-tick.png"
// import "./Navbar.css"
const Competitions = () => {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1200,
  //   });
  // }, []);

  const showEvents = (eventCategory) => {


    document.querySelectorAll(".compet").forEach((event) => {
      event.style.display = "none";
    });

    document.querySelector(`.compet.${eventCategory}`).style.display = "flex";

    document.querySelectorAll(".events-menu li").forEach((button) => {
      button.classList.remove("events-active");
    });

    document
      .querySelector(`.${eventCategory}-button`)
      .classList.add("events-active");
  };

  return (
    <div id="Events" className="events-wrapper">
      <div className="Competitions-top spacer"></div>
      <h1 className="center Events-new">Events</h1>
      <div className="events-menu">
        <ul>
          <li
            className="technical-event-button events-active"
            onClick={() => showEvents("technical-event")}
          >
            Technical
          </li>

          <li
            className="cultural-event-button"
            onClick={() => showEvents("cultural-event")}
          >
            Cultural
          </li>

          <li
            className="sports-event-button"
            onClick={() => showEvents("sports-event")}
          >
            Sports
          </li>

          <li
            className="media-event-button "
            onClick={() => showEvents("media-event")}
          >
            Media
          </li>
        </ul>
      </div>
      
      <div className="events technical-event compet">
        <EventBox
          eventName="Quizzi&#88;"
          eventImgSrc="https://i.postimg.cc/tTG6Z62L/qw-ele-7.webp"
          eventRole="
Team Composition: 3 Person/Team
2 Rounds:- (Buzzer Round and Passing Round) "
          eventLink="Quizzix"
        />
                <EventBox
          eventName="Surprize Event"
          eventImgSrc="https://i.postimg.cc/SxvXk9px/17973871.jpg"
          eventRole="Team Composition: 3 Person/Team
Rounds:- Suprise"
          eventLink="SurpiseEvent"
        />
        <EventBox
          eventName="Treasure Hunt"
          eventImgSrc="https://i.postimg.cc/DwCvMpm2/71-Dl6-Tkkko-L-AC-UF1000-1000-QL80.jpg"
          eventRole="Team Composition: 5 Person/Team
Rounds:- Surprise
"
          eventLink="TreasureHunt"
        />

      </div>

      <div className="events sports-event compet">
        <EventBox
          eventName="Bo&#120013; Cricket"
          eventImgSrc="https://media.hudle.in/venues/b11383c4-36e2-48f0-8b19-e6e7c1b77d55/photo/663934d9ca9699e474f795d2b77b956a59377c0e"
          eventRole="
Each team shall be represented by 6 players with 1 substitute"
          eventLink="BoxCricket"
        />
        {/* <EventBox
          eventName="Futsal"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/0/06/Futebol_Salao_Pan2007.jpg"
          eventRole="Date:
Time:
Venue:
Team Size: 5v5 (4 and a goalie)"
          eventLink="Futsal"
        /> */}
        <EventBox
          eventName="Game of Tag"
          eventImgSrc="https://global-uploads.webflow.com/60dfb7bd2f9507f7616a7cac/60e11d929d6c02ebfd8b62ae_41-DSC02043.jpg"
          eventRole="
Team Composition: 1 boy and 1 girl from each branch "
          eventLink="GameofTag"
        />
        <EventBox
          eventName="Tug of War"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a3/Irish_600kg_euro_chap_2009_%28cropped%29.JPG"
          eventRole="
Team Composition: 1 boy and 1 girl from each branch "
          eventLink="TugofWar"
        />
                <EventBox
          eventName="BGMI"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/en/6/63/Battleground_Mobile_India.webp"
          eventRole="
Team Composition: Each team is represented by 4 players (1 substitute to be declared) "
          eventLink="BGMI"
        />
                      <EventBox
          eventName="5 leg Race"
          eventImgSrc="https://i.postimg.cc/yxgvSy8h/The-Royal-Navy-during-the-Second-World-War-A17268.jpg"
          eventRole="
Get ready to be exhilarated at the 5 Men's Leg Race Championship! Skilled athletes will take center stage, showcasing their lightning-fast speed and teamwork. "
          eventLink="5MenlegRace"
        />
              <EventBox
          eventName="Binge Eating"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/1/1c/Eating_Comp_21aug2005.jpg"
          eventRole="
Team Composition: 1 participant per branch ( 1 boy + 1 girl ) "
          eventLink="BingeEating"
        />
      </div>


      <div className="events cultural-event compet">

        <EventBox
          eventName="Energetic Euphoria E&#120013;press (Dance)"
          eventImgSrc="https://www.stgpresents.org/images/education/Dance-This/dt26.jpg"
          eventRole="Team Composition: Group+Duet+Solo
Duration: Variable check more info"
          eventLink="EnergeticEuphoriaExpress"
        />
        {/* <EventBox 
          eventName="Energetic Euphoria Express (Duet)"
          eventImgSrc="https://live.staticflickr.com/65535/40820359833_08709cae31_b.jpg"
          eventRole="Date:
Time:
Venue:
Team Size: "
          eventLink="EnergeticEuphoriaExpressDuetDance"
        />
        <EventBox 
          eventName="Energetic Euphoria Express (Solo)"
          eventImgSrc="https://live.staticflickr.com/65535/40820359833_08709cae31_b.jpg"
          eventRole="Date:
Time:
Venue:
Team Size: "
          eventLink="EnergeticEuphoriaExpressSoloDance"
        /> */}
        <EventBox
          eventName="The Vocal Nirvana"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/8/87/Roxette_at_the_Beacon%2C_September_2012.jpg"
          eventRole="VOCAL NIRVANA consists of 3 categories :
SOLO SENSATION:- Solo singing competition.
DYNAMIC DUO:- Duet singing competition.
INSTRUMENTS:- Battle of instruments
"
          eventLink="TheVocalNirvana"
        />
             {/* <EventBox
          eventName="The Vocal Nirvana (Duet)"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/8/87/Roxette_at_the_Beacon%2C_September_2012.jpg"
          eventRole="
DYNAMIC DUO :- duet singing competition.
Date:
Time:
Venue:
Team Size: 2"
          eventLink="TheVocalNirvana"
        /> */}
                     {/* <EventBox
          eventName="Battle of Bands(Instrument)"
          eventImgSrc="https://i.postimg.cc/K8zv78rZ/main-230061-80940-549-battle-fo-the-bands-1.jpg"
          eventRole="
ROCKIN’ BAND :- Battle of bands.
Date:
Time:
Venue:
Team Size: max 4"
          eventLink="TheVocalNirvana"
        /> */}

        <EventBox
          eventName="Nukkad Natak (Skit)"
          eventImgSrc="https://i.postimg.cc/59skD9WY/406030391-18019993525921674-8036111331664859898-n.jpg"
          eventRole="
Team Composition: 5 minimum. No maximum limit
"
          eventLink="NukkadNatak"
        />

<EventBox
          eventName="The Retail Therapy (Fashion show)"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Magdalena_Frackowiak.jpg/800px-Magdalena_Frackowiak.jpg"
          eventRole="
Team Composition: 6-10
Duration: 10 mins maximum (Including setup and the performance both).
"
          eventLink="TheRetailTherapy"
        />

        <EventBox
          eventName="Jokes Jubilation (Stand up)"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Zakir_khan_2.jpg/440px-Zakir_khan_2.jpg"
          eventRole="
Team Composition: 1 member per branch
Duration: Each performance should be between 5-7 mins "
          eventLink="JokesJubilation"
        />
        <EventBox
          eventName="Rainbow Palette (Face painting)"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/7/7e/Self_traditional_folk_face_painting_%282%29.jpg"
          eventRole="
Team Composition: 2 members (Painter and the Model)
Duration: 2 hour "
          eventLink="RainbowPalette"
        />

        {/* <EventBox
          eventName="Instrument"
          eventImgSrc="https://i.postimg.cc/wBKSzm6m/violin-instrumental-orchestra-chennai-weddings-events-1000x1000.webp"
          eventRole="Date:
Time:
Venue:
Team Size: "
          eventLink="TheRetailTherapy"
        /> */}
      </div>

      <div className="events media-event compet">
        <EventBox
          eventName="Photography"
          eventImgSrc="https://i.postimg.cc/VLdbgtK0/Photography.jpg"
          eventRole="
Participant Eligibility: All the students of NIT GOA are eligible to participate in the photo submission activity. "
          eventLink="Photography"
        />
                <EventBox
          eventName="Digital Art"
          eventImgSrc="https://i.postimg.cc/Kvn1ydhc/images.jpg"
          eventRole="
Eligibility: The competition is open to all interested students from NIT GOA. "
          eventLink="DigitalArt"
        />
        </div>
        <div style={{"position":"relative"}} className="center Register">
        {/* <a href="" download><button className="Download-btn">Brochure<i className="fa fa-download" aria-hidden="true"></i></button></a> */}
        <a onClick={()=>{alert("Download will begin Soon!");}} href="https://drive.google.com/uc?export=download&id=16QtKWVMy14sAet4rKPiCESnc-I11u0lp" download>
          <ElectricButton ButtonName="Brochure"/>
        </a>
        </div>

    </div>
  );
};

export default Competitions;
