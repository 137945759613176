import React from 'react';
import "./Sponsors.css"
const Sponsors = () => {
  return (
    <div>  
    <div className="sponsors">
    <div className="spacer sponsors-spacer"> </div>      
    </div>
    </div>
  );
};

export default Sponsors;
