import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <div id="error-page">
      <div>
      <h1 className="center">Oops!</h1>
      <p className="center">Sorry, an une<span>X</span>pected error has occurred.</p>
      </div>
      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
    </div>
  );
}