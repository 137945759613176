/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 Dronehover.glb 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Dronehover.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=>{
    // actions.take_off_and_land.play();
    actions.hover.play();

  },[])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="RootNode0" scale={0.006}>
          <group name="skeletal1">
            <primitive object={nodes.root2} />
            <skinnedMesh name="drone44" geometry={nodes.drone44.geometry} material={materials.Material_0} skeleton={nodes.drone44.skeleton}/>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/Dronehover.glb')
