import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/oscilloscope.gltf')
  return (
    <group {...props} dispose={null}>
      <group scale={0.07}>
        <group position={[0, 12.382, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cube_Material014_0.geometry} material={materials['Material.014']} />
          <mesh geometry={nodes.Cube_Material004_0.geometry} material={materials['Material.004']} />
          <mesh geometry={nodes.Cube_Material_0.geometry} material={materials.Material} />
          <mesh geometry={nodes.Cube_Material016_0.geometry} material={materials['Material.016']} />
        </group>
        <group position={[-6.811, 16.351, 21.006]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cube004_Material001_0.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cube004_Glass001_0.geometry} material={materials['Glass.001']} />
          <mesh geometry={nodes.Cube004_Glass_0.geometry} material={materials.Glass} />
        </group>
        <group position={[-15.916, 20.874, 25.158]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[12.93, 9.139, 26.254]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder006_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder006_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[15.638, 7.294, 24.566]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder015_Material005_0.geometry} material={materials['Material.005']} />
          <mesh geometry={nodes.Cylinder015_Material004_0.geometry} material={materials['Material.004']} />
          <mesh geometry={nodes.Cylinder015_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[-13.003, 3.63, 24.773]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder019_Material001_0.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cylinder019_Material005_0.geometry} material={materials['Material.005']} />
        </group>
        <group position={[-15.237, 3.63, 24.773]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder020_Material001_0.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cylinder020_Material002_0.geometry} material={materials['Material.002']} />
        </group>
        <group position={[0, 12.382, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cube010_Material_0.geometry} material={materials.Material} />
          <mesh geometry={nodes.Cube010_Material004_0.geometry} material={materials['Material.004']} />
        </group>
        <group position={[10.808, 20.874, 25.235]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder022_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder022_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[2.352, 5.919, 25.235]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder002_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder002_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[-2.229, 5.919, 25.235]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder009_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder009_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[-8.675, 7.9, 25.8]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder003_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder003_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[12.93, 9.139, 25.8]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder004_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder004_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[15.639, 3.63, 24.773]} scale={100}>
          <mesh geometry={nodes.Cylinder024_Material001_0.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cylinder024_Material002_0.geometry} material={materials['Material.002']} />
        </group>
        <group position={[15.595, 15.495, 24.773]} scale={100}>
          <mesh geometry={nodes.Cylinder014_Material001_0.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cylinder014_Material005_0.geometry} material={materials['Material.005']} />
        </group>
        <group position={[7.761, 3.569, 24.566]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder011_Material005_0.geometry} material={materials['Material.005']} />
          <mesh geometry={nodes.Cylinder011_Material004_0.geometry} material={materials['Material.004']} />
          <mesh geometry={nodes.Cylinder011_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[0, 12.382, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cube019_Material_0.geometry} material={materials.Material} />
          <mesh geometry={nodes.Cube019_Material004_0.geometry} material={materials['Material.004']} />
        </group>
        <group position={[0, 12.382, 0]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={100}>
          <mesh geometry={nodes.Cube021_Material_0.geometry} material={materials.Material} />
          <mesh geometry={nodes.Cube021_Material004_0.geometry} material={materials['Material.004']} />
        </group>
        <group position={[3.52, 5.934, -24.572]} rotation={[Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder028_Material005_0.geometry} material={materials['Material.005']} />
          <mesh geometry={nodes.Cylinder028_Material001_0.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cylinder028_Material004_0.geometry} material={materials['Material.004']} />
        </group>
        <group position={[1, 5.975, -24.235]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder033_Material013_0.geometry} material={materials['Material.013']} />
          <mesh geometry={nodes.Cylinder033_Material012_0.geometry} material={materials['Material.012']} />
        </group>
        <group position={[9.202, 4.875, -24.235]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder030_Material013_0.geometry} material={materials['Material.013']} />
          <mesh geometry={nodes.Cylinder030_Material012_0.geometry} material={materials['Material.012']} />
        </group>
        <group position={[6.488, 4.875, -24.284]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder036_Material013_0.geometry} material={materials['Material.013']} />
          <mesh geometry={nodes.Cylinder036_Material016_0.geometry} material={materials['Material.016']} />
          <mesh geometry={nodes.Cylinder036_Material010_0.geometry} material={materials['Material.010']} />
        </group>
        <group position={[4.761, 15.328, 25.8]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cylinder037_Material003_0.geometry} material={materials['Material.003']} />
          <mesh geometry={nodes.Cylinder037_Material001_0.geometry} material={materials['Material.001']} />
        </group>
        <group position={[19.572, 12.531, 20.173]} rotation={[2.238, 0, 0]} scale={100}>
          <mesh geometry={nodes.Cube009_Material_0.geometry} material={materials.Material} />
          <mesh geometry={nodes.Cube009_Material014_0.geometry} material={materials['Material.014']} />
        </group>
        <mesh geometry={nodes.Cube001_Material001_0.geometry} material={materials['Material.001']} position={[0, 12.382, -0.063]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube002_Material001_0.geometry} material={materials['Material.001']} position={[-0.01, 12.382, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube003_Material002_0.geometry} material={materials['Material.002']} position={[13.148, 0.425, 21.792]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube006_Material001_0.geometry} material={materials['Material.001']} position={[16.985, 22.666, -25.412]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube007_Material008_0.geometry} material={materials['Material.008']} position={[0, 13.534, -24.306]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube008_Material014_0.geometry} material={materials['Material.014']} position={[19.52, 12.641, 20.033]} rotation={[2.238, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder007_Material001_0.geometry} material={materials['Material.001']} position={[-16.563, 2.19, 24.361]} scale={100} />
        <mesh geometry={nodes.Cylinder021_Material001_0.geometry} material={materials['Material.001']} position={[-17.06, 11.356, -24.351]} rotation={[Math.PI, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube011_Material001_0.geometry} material={materials['Material.001']} position={[-8.624, 3.225, 24.25]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder023_Material001_0.geometry} material={materials['Material.001']} position={[-15.243, 6.693, 24.33]} scale={100} />
        <mesh geometry={nodes.Cylinder008_Material001_0.geometry} material={materials['Material.001']} position={[-15.888, 12.613, 24.33]} scale={100} />
        <mesh geometry={nodes.Cylinder005_Material001_0.geometry} material={materials['Material.001']} position={[-2.418, 3.683, 24.33]} scale={100} />
        <mesh geometry={nodes.Cube012_Material001_0.geometry} material={materials['Material.001']} position={[8.002, 9.529, 27.279]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={100} />
        <mesh geometry={nodes.Cube013_Material001_0.geometry} material={materials['Material.001']} position={[5.372, 19.895, 27.279]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube014_Material001_0.geometry} material={materials['Material.001']} position={[13.253, 17.53, 27.279]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={100} />
        <mesh geometry={nodes.Cube015_Material006_0.geometry} material={materials['Material.006']} position={[-8.624, 3.224, 24.437]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube016_Material006_0.geometry} material={materials['Material.006']} position={[8.002, 9.529, 24.437]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={100} />
        <mesh geometry={nodes.Cube017_Material006_0.geometry} material={materials['Material.006']} position={[5.372, 19.895, 24.437]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube018_Material006_0.geometry} material={materials['Material.006']} position={[13.253, 17.53, 24.437]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder025_Material007_0.geometry} material={materials['Material.007']} position={[11.081, 3.552, 24.23]} scale={100} />
        <mesh geometry={nodes.Cylinder026_Material001_0.geometry} material={materials['Material.001']} position={[13.212, 3.552, 24.796]} scale={100} />
        <mesh geometry={nodes.Cylinder017_Material001_0.geometry} material={materials['Material.001']} position={[11.084, 15.402, 24.796]} scale={100} />
        <mesh geometry={nodes.Cylinder012_Material001_0.geometry} material={materials['Material.001']} position={[15.547, 18.443, 24.796]} scale={100} />
        <mesh geometry={nodes.Cylinder010_Material010_0.geometry} material={materials['Material.010']} position={[17.936, 12.389, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder013_Material001_0.geometry} material={materials['Material.001']} position={[-18.172, 12.39, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder016_Material001_0.geometry} material={materials['Material.001']} position={[-17.943, 12.937, 23.26]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder018_Material001_0.geometry} material={materials['Material.001']} position={[5.28, 3.552, 24.868]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder027_Material011_0.geometry} material={materials['Material.011']} position={[5.28, 3.552, 24.868]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube022_Material011_0.geometry} material={materials['Material.011']} position={[12.911, 5.016, -25.112]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder029_Material001_0.geometry} material={materials['Material.001']} position={[10.975, 4.834, -24.852]} rotation={[Math.PI, 0, 0]} scale={100} />
        <mesh geometry={nodes.Plane_Material008_0.geometry} material={materials['Material.008']} position={[6.485, 4.783, -25.025]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.NurbsPath_Material005_0.geometry} material={materials['Material.005']} position={[12.781, 4.84, -48.13]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube005_Material011_0.geometry} material={materials['Material.011']} position={[47.946, 0.058, -1.291]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder031_Material011_0.geometry} material={materials['Material.011']} position={[47.946, 0.058, -2.473]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder032_Material013_0.geometry} material={materials['Material.013']} position={[48.714, 0.043, -3.11]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder034_Material001_0.geometry} material={materials['Material.001']} position={[-16.982, 2.156, -24.43]} rotation={[Math.PI, 0, 0]} scale={100} />
        <mesh geometry={nodes.NurbsPath001_Material015_0.geometry} material={materials['Material.015']} position={[12.669, 4.84, -48.13]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.NurbsPath002_Material015_0.geometry} material={materials['Material.015']} position={[12.963, 4.84, -48.13]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cylinder035_Material001_0.geometry} material={materials['Material.001']} position={[6.485, 2.971, -24.439]} rotation={[Math.PI, 0, 0]} scale={100} />
        <mesh geometry={nodes.Cube023_Material009_0.geometry} material={materials['Material.009']} position={[0, 24.633, 4.872]} rotation={[2.238, 0, 0]} scale={100} />
        <mesh geometry={nodes.Plane001_Material002_0.geometry} material={materials['Material.002']} position={[-0.116, 12.245, 24.261]} scale={100} />
        <mesh geometry={nodes.Plane002_Material002_0.geometry} material={materials['Material.002']} position={[3.14, 22.874, 24.261]} rotation={[0, 0, -Math.PI / 2]} scale={100} />
        <mesh geometry={nodes.Plane003_Material002_0.geometry} material={materials['Material.002']} position={[11.539, 5.549, 24.261]} rotation={[0, 0, -Math.PI / 2]} scale={100} />
        <mesh geometry={nodes.Plane004_Material002_0.geometry} material={materials['Material.002']} position={[11.539, 22.874, 24.261]} rotation={[0, 0, -Math.PI / 2]} scale={100} />
        <mesh geometry={nodes.Plane005_Material002_0.geometry} material={materials['Material.002']} position={[11.539, 12.671, 24.261]} rotation={[0, 0, -Math.PI / 2]} scale={100} />
        <mesh geometry={nodes.Plane006_Material002_0.geometry} material={materials['Material.002']} position={[-12.616, 11.025, 24.261]} rotation={[0, 0, -Math.PI / 2]} scale={100} />
        <mesh geometry={nodes.Cylinder001_Material014_0.geometry} material={materials['Material.014']} position={[18.588, 12.383, 20.442]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      </group>
    </group>
  )
}

useGLTF.preload('/oscilloscope.gltf')
