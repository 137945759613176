import React from 'react'

const CommingSoon = () => {
  return (
    <div>
      <h1 className='center'>Coming soon!</h1>
    </div>
  )
}

export default CommingSoon
