import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import ElectricButton from "./ElectricButton";
import "./EventsInfoPage.css"
import supabase from "../supabase";
import greentick from "../Assets/Green-tick.png"
import wrongtick from "../Assets/wrong-tick.png"
import { Link } from "react-router-dom";

var c = 0;

function added() {
  const out = document.getElementsByClassName("Registering-holder");

    // allow 1px inaccuracy by adding 1
    // var isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
    // console.log(out.scrollHeight - out.clientHeight,  out.scrollTop + 1);
    // var newElement = document.createElement("div");
    // newElement.innerHTML = c++;
    // out.appendChild(newElement);

    // scroll to bottom if isScrolledToBotto
    // if(isScrolledToBottom)
      // out.scrollTop = out.scrollHeight - out.clientHeight;
      console.log("dded");
      out.scrollTop = out.scrollHeight;

}
const EventsInfoPage = ({
  eventName,
  eventImgSrc,
  eventLink,
  eventRules,
  eventPrize,
  AboutEvent,
}) => {


  const popup1 = document.getElementById("popup1");

  const openPopup = () => {
    if (popup1) {
      popup1.classList.add("open-popup");
    }
  };

  const closePopup = () => {
    if (popup1) {
      popup1.classList.remove("open-popup");
    }
  };
  const [errors,seterrors]=useState("");
  const [errorimg,seterrorimg]=useState();
  const [error1,seterror1]=useState("");
  const [email,setemail]=useState("");
  const [name,setname]=useState("");
  const [participant2,setparticipant2]=useState("");
  const [participant3,setparticipant3]=useState("");
  const [participant4,setparticipant4]=useState("");
  const [participant5,setparticipant5]=useState("");
  const [participant6,setparticipant6]=useState("");
  const [participant7,setparticipant7]=useState("");
  const [participant8,setparticipant8]=useState("");
  const [participant9,setparticipant9]=useState("");
  const [participant10,setparticipant10]=useState("");
  const [participant11,setparticipant11]=useState("");
  const [participant12,setparticipant12]=useState("");
  const [participant13,setparticipant13]=useState("");
  const [participant14,setparticipant14]=useState("");
  const [participant15,setparticipant15]=useState("");
  const [participant16,setparticipant16]=useState("");
  const [participant17,setparticipant17]=useState("");
  const [participant18,setparticipant18]=useState("");
  const [participant19,setparticipant19]=useState("");
  const [participant20,setparticipant20]=useState("");
  const [participant21,setparticipant21]=useState("");
  const [participant22,setparticipant22]=useState("");
  const [participant23,setparticipant23]=useState("");
  const [participant24,setparticipant24]=useState("");
  const [participant25,setparticipant25]=useState("");


  const [mob,setmob]=useState("");
  const [branch,setbranch]=useState("");
  const [passwordVisible,setpasswordVisible]=useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [authuser, setAuthUser] = useState(null);

  const closeButtonClick = () => {
    document.getElementsByClassName("login-box")[0].style.visibility="hidden";
    document.getElementsByClassName("signup-box")[0].style.visibility="hidden";
    document.getElementsByClassName("register-box-1")[0].style.visibility="hidden";
  };
  useEffect(()=>{
    const checkAuthState = async (session) => {
      if (session?.user) {
        // User is authenticated
        setUserEmail(session.user.email);
      } else {
        seterror1("Please Login First!");
        seterrors("");
        seterrorimg(wrongtick);
        openPopup()
        // alert("Please Login First!");
      }
    };

    // // Check authentication state on component mount
    // checkAuthState();

    const authListener = supabase.auth.onAuthStateChange((_, session) => {
      checkAuthState(session);
    });
    
  },[]);
const rulesArray = eventRules.split('\n');
  const handleregistration= async() => {
    try{
  const { data, error } = await supabase
  .from(eventLink)
  .insert([
    {
       Name: name, Branch: branch , Mobile: mob, Email: userEmail,Participant2 :participant2,Participant3 :participant3,Participant4 :participant4,Participant5 :participant5,Participant6 :participant6,Participant7 :participant7,Participant8 :participant8,Participant9 :participant9,Participant10 :participant10,Participant11 :participant11,Participant12 :participant12,Participant13 :participant13,Participant14 :participant14,Participant15 :participant15,Participant16 :participant16,Participant17 :participant17,Participant18 :participant18,Participant19 :participant19,Participant20 :participant20,Participant21 :participant21,Participant22 :participant22,Participant23 :participant23,Participant24 :participant24,Participant25 :participant25
      },
  ])
  .select()
  console.log(error);
  console.log(error.message);
  console.log(error.code);

  if(error.code===23505||error.code==="23505"){
    seterror1("Already Registered");
    seterrors("");
    seterrorimg(greentick);
    openPopup()
    // alert("Already Registered");
  }
  else if(error.code===23502||error.code==="23502"||error.code==23502){
    seterror1("Error");
    seterrors("Please login first! you can login by clicking the three bars in navigation bar (then click register for first time users)");
    seterrorimg(wrongtick);
    openPopup();
  }
  else if(error){
    seterror1("Error");
    seterrors(error.message);
    seterrorimg(wrongtick);
    openPopup();

    // alert(error.message);
  }
  else{
    seterror1("Registration Successful");
    seterrors("But only one or 2 teams from each department will be selected");
    seterrorimg(greentick);
    openPopup();
    closeButtonClick();
  }
  // if(error==null || error==="null"||error===null){
  //   seterror1("Registration Successful");
  //   seterrors("But only one or 2 teams from each department will be selected");
  //   seterrorimg(greentick);
  //   openPopup();
  //   closeButtonClick();
  // }
}
catch(error){
  // console.log(error.code);
  // alert(error.message);
}
}

  const showEvents = (eventCategory) => {
    document.querySelectorAll(".infoPage-details").forEach((event) => {
      event.style.display = "none";
    });

    document.querySelector(`.infoPage-details.${eventCategory}`).style.display = "block";

    document.querySelectorAll(".events-menu li").forEach((button) => {
      button.classList.remove("events-active");
    });

    document
      .querySelector(`.${eventCategory}-button`)
      .classList.add("events-active");
  };
  return (
    <div>
      <div className="Events-main">
        <Navbar></Navbar>
        <div className="Events-info">

          <div className="Events-info-main">
            <div className="Events-info-main-left">
              <img className="Events-info-img" src={eventImgSrc} alt={eventName}/>
              <p>{eventPrize}</p>
              {/* <ElectricButton ButtonName="Download-Brocure"></ElectricButton> */}
              <a onClick={()=>{alert("Your Download will start soon!");}} href="https://drive.google.com/uc?export=download&id=16QtKWVMy14sAet4rKPiCESnc-I11u0lp" download><button className="Download-btn">Brochure<i className="fa fa-download" aria-hidden="true"></i></button></a>
              <div className="Register1">
              <Link  onClick={()=>{    document.getElementsByClassName("login-box")[0].style.visibility="hidden";
    document.getElementsByClassName("signup-box")[0].style.visibility="hidden";
    document.getElementsByClassName("register-box-1")[0].style.visibility="visible";}}>
                <ElectricButton ButtonName="Register"></ElectricButton>
              </Link>
            </div>
            
          </div>

            <div className="Events-info-main-right">
            {/* <div className="events-menu">
        <ul>
          <li
            className="About-event-button events-active"
            onClick={() => showEvents("About-event")}
          >
            About
          </li>

          <li
            className="Rules-and-regulations-event-button"
            onClick={() => showEvents("Rules-and-regulations-event")}
          >
            Rules and Regulations
          </li>
        </ul>
      </div> */}
            <h1>{eventName}</h1>

              <div className="Rules-and-regulations-event infoPage-details">
                <h3>Rules & Regulations:-</h3>
              <ul>
                {/* {eventRules} */}
                  {rulesArray.map((rule, index) => (
                    <li key={index} className="rules">{rule}</li>
                  ))}
                </ul>
              </div>
              <div className="About-event infoPage-details">
                <p>{AboutEvent}</p>
              </div>

              {/* <div className="Register1">
              <Link to={eventLink}>
                <ElectricButton ButtonName="Register"></ElectricButton>
              </Link>
            </div> */}

            </div>

          </div>

        </div>
      </div>


      <div className=" ">
      <div className="register-box-1"style={{"visibility": "hidden"}} >
        <i onClick={closeButtonClick} className="fa-regular fa-circle-xmark close-popup"></i>
        <div className="Registering-holder">
            <div className="username-box first-name">
            <input onChange={(event)=>{setname(event.target.value)}}  type="text" placeholder="Name" className="TextArea" />
            </div>
            <div className="username-box">
            <input   onChange={(event)=>{setmob(event.target.value)}}  type="tel" pattern="[0-9]{10}" placeholder="Mobile Number" className="TextArea" />
            </div>
            <div className="username-box">
            <input  onChange={(event)=>{setbranch(event.target.value)}}  type="text" placeholder="Branch" className="TextArea" />
            </div>
            <button id="Participant2-btn" className="add-btn" onClick={()=>{document.getElementById('Participant2').style.display="block";document.getElementById('Participant2-btn').style.display="none";document.getElementById('Participant3-btn').style.display="block";}}>Add Participant</button>
            <div className="username-box" id="Participant2"  style={{"display": "none"}}>
            <input  onChange={(event)=>{setparticipant2(event.target.value)}}  type="text" placeholder="Participant 2 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant3-btn" className="add-btn" onClick={()=>{document.getElementById('Participant3').style.display="block";document.getElementById('Participant3-btn').style.display="none";document.getElementById('Participant4-btn').style.display="block";}}>Add Participant</button>
            <div className="username-box" id="Participant3"  style={{"display": "none"}}>
            <input  onChange={(event)=>{setparticipant3(event.target.value)}}  type="text" placeholder="Participant 3 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant4-btn" className="add-btn" onClick={()=>{document.getElementById('Participant4').style.display="block";document.getElementById('Participant4-btn').style.display="none";document.getElementById('Participant5-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant4"  style={{"display": "none"}}>
            <input  onChange={(event)=>{setparticipant4(event.target.value)}}  type="text" placeholder="Participant 4 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant5-btn" className="add-btn" onClick={()=>{document.getElementById('Participant5').style.display="block";document.getElementById('Participant5-btn').style.display="none";document.getElementById('Participant6-btn').style.display="block";  added();}}>Add Participant</button>
            
            <div className="username-box" id="Participant5"  style={{"display": "none"}}>
            <input onChange={(event)=>{setparticipant5(event.target.value)}}  type="text" placeholder="Participant 5 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant6-btn" className="add-btn" onClick={()=>{document.getElementById('Participant6').style.display="block";document.getElementById('Participant6-btn').style.display="none";document.getElementById('Participant7-btn').style.display="block";added();}}>Add Participant</button>
            
            <div className="username-box" id="Participant6"  style={{"display": "none"}}>
            <input  onChange={(event)=>{setparticipant6(event.target.value)}}  type="text" placeholder="Participant 6 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant7-btn" className="add-btn" onClick={()=>{document.getElementById('Participant7').style.display="block";document.getElementById('Participant7-btn').style.display="none";document.getElementById('Participant8-btn').style.display="block";added();}}>Add Participant</button>

            <div className="username-box" id="Participant7"  style={{"display": "none"}}>
            <input onChange={(event)=>{setparticipant7(event.target.value)}}  type="text" placeholder="Participant 7 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant8-btn" className="add-btn" onClick={()=>{document.getElementById('Participant8').style.display="block";document.getElementById('Participant8-btn').style.display="none";document.getElementById('Participant9-btn').style.display="block";added();}}>Add Participant</button>

            <div className="username-box" id="Participant8"  style={{"display": "none"}}>
            <input onChange={(event)=>{setparticipant8(event.target.value)}}  type="text" placeholder="Participant 8 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant9-btn" className="add-btn" onClick={()=>{document.getElementById('Participant9').style.display="block";document.getElementById('Participant9-btn').style.display="none";document.getElementById('Participant10-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant9"  style={{"display": "none"}}>
            <input onChange={(event)=>{setparticipant9(event.target.value)}}  type="text" placeholder="Participant 9 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant10-btn" className="add-btn" onClick={()=>{document.getElementById('Participant10').style.display="block";document.getElementById('Participant10-btn').style.display="none";document.getElementById('Participant11-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant10"  style={{"display": "none"}}>
            <input name="participant10name" onChange={(event)=>{setparticipant10(event.target.value)}}  type="text" placeholder="Participant 10 Name" className="TextArea" />
            </div>
            <button style={{"display": "none"}} id="Participant11-btn" className="add-btn" onClick={()=>{document.getElementById('Participant11').style.display="block";document.getElementById('Participant11-btn').style.display="none";document.getElementById('Participant12-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant11"  style={{"display": "none"}}>
            <input name="participant11name" onChange={(event)=>{setparticipant11(event.target.value)}}  type="text" placeholder="Participant 11 Name" className="TextArea" />
            </div>
            <button style={{"display": "none"}} id="Participant12-btn" className="add-btn" onClick={()=>{document.getElementById('Participant12').style.display="block";document.getElementById('Participant12-btn').style.display="none";document.getElementById('Participant13-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant12"  style={{"display": "none"}}>
            <input name="participant12name" onChange={(event)=>{setparticipant12(event.target.value)}}  type="text" placeholder="Participant 12 Name" className="TextArea" />
            </div>
            <button style={{"display": "none"}} id="Participant13-btn" className="add-btn" onClick={()=>{document.getElementById('Participant13').style.display="block";document.getElementById('Participant13-btn').style.display="none";document.getElementById('Participant14-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant13"  style={{"display": "none"}}>
            <input name="participant13name" onChange={(event)=>{setparticipant13(event.target.value)}}  type="text" placeholder="Participant 13 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant14-btn" className="add-btn" onClick={()=>{document.getElementById('Participant14').style.display="block";document.getElementById('Participant14-btn').style.display="none";document.getElementById('Participant15-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant14"  style={{"display": "none"}}>
            <input name="participant14name" onChange={(event)=>{setparticipant14(event.target.value)}}  type="text" placeholder="Participant 14 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant15-btn" className="add-btn" onClick={()=>{document.getElementById('Participant15').style.display="block";document.getElementById('Participant15-btn').style.display="none";document.getElementById('Participant16-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant15"  style={{"display": "none"}}>
            <input name="participant15name" onChange={(event)=>{setparticipant15(event.target.value)}}  type="text" placeholder="Participant 15 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant16-btn" className="add-btn" onClick={()=>{document.getElementById('Participant16').style.display="block";document.getElementById('Participant16-btn').style.display="none";document.getElementById('Participant17-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant16"  style={{"display": "none"}}>
            <input name="participant16name" onChange={(event)=>{setparticipant16(event.target.value)}}  type="text" placeholder="Participant 16 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant17-btn" className="add-btn" onClick={()=>{document.getElementById('Participant17').style.display="block";document.getElementById('Participant17-btn').style.display="none";document.getElementById('Participant18-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant17"  style={{"display": "none"}}>
            <input name="participant17name" onChange={(event)=>{setparticipant17(event.target.value)}}  type="text" placeholder="Participant 17 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant18-btn" className="add-btn" onClick={()=>{document.getElementById('Participant18').style.display="block";document.getElementById('Participant18-btn').style.display="none";document.getElementById('Participant19-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant18"  style={{"display": "none"}}>
            <input name="participant18name" onChange={(event)=>{setparticipant18(event.target.value)}}  type="text" placeholder="Participant 18 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant19-btn" className="add-btn" onClick={()=>{document.getElementById('Participant19').style.display="block";document.getElementById('Participant19-btn').style.display="none";document.getElementById('Participant20-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant19"  style={{"display": "none"}}>
            <input name="participant19name" onChange={(event)=>{setparticipant19(event.target.value)}}  type="text" placeholder="Participant 19 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant20-btn" className="add-btn" onClick={()=>{document.getElementById('Participant20').style.display="block";document.getElementById('Participant20-btn').style.display="none";document.getElementById('Participant21-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant20"  style={{"display": "none"}}>
            <input name="participant20name" onChange={(event)=>{setparticipant20(event.target.value)}}  type="text" placeholder="Participant 20 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant21-btn" className="add-btn" onClick={()=>{document.getElementById('Participant21').style.display="block";document.getElementById('Participant21-btn').style.display="none";document.getElementById('Participant22-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant21"  style={{"display": "none"}}>
            <input name="participant21name" onChange={(event)=>{setparticipant21(event.target.value)}}  type="text" placeholder="Participant 21 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant22-btn" className="add-btn" onClick={()=>{document.getElementById('Participant22').style.display="block";document.getElementById('Participant22-btn').style.display="none";document.getElementById('Participant23-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant22"  style={{"display": "none"}}>
            <input name="participant22name" onChange={(event)=>{setparticipant22(event.target.value)}}  type="text" placeholder="Participant 22 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant23-btn" className="add-btn" onClick={()=>{document.getElementById('Participant23').style.display="block";document.getElementById('Participant23-btn').style.display="none";document.getElementById('Participant24-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant23"  style={{"display": "none"}}>
            <input name="participant23name" onChange={(event)=>{setparticipant23(event.target.value)}}  type="text" placeholder="Participant 23 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant24-btn" className="add-btn" onClick={()=>{document.getElementById('Participant24').style.display="block";document.getElementById('Participant24-btn').style.display="none";document.getElementById('Participant25-btn').style.display="block";}}>Add Participant</button>

            <div className="username-box" id="Participant24"  style={{"display": "none"}}>
            <input name="participant24name" onChange={(event)=>{setparticipant24(event.target.value)}}  type="text" placeholder="Participant 24 Name" className="TextArea" />
            </div>
            <button  style={{"display": "none"}} id="Participant25-btn" className="add-btn" onClick={()=>{document.getElementById('Participant25').style.display="block";document.getElementById('Participant25-btn').style.display="none";}}>Add Participant</button>

            <div className="username-box" id="Participant25"  style={{"display": "none"}}>
            <input name="participant25name" onChange={(event)=>{setparticipant25(event.target.value)}}  type="text" placeholder="Participant 25 Name" className="TextArea" />
            </div>
            <button type="submit" className="login-btn" onClick={handleregistration}>Register</button>
            <p>*Note participants must be from same department</p>
            </div>
            
        </div>
        </div>



        <div className="popup" id="popup1">
            <img src={errorimg} alt="Green tick"/>
            <h2>{error1}</h2>
            <p>{errors}</p>
            <button className="ok-btn" onClick={closePopup}>OK</button>
        </div>
    </div>
  );
};
// eventsInfoPage.propTypes = {
//   eventName: PropTypes.string.isRequired,
//   // eventImgSrc: PropTypes.string.isRequired,
//   // eventRule: PropTypes.string.isRequired,
//   // eventPrize: PropTypes.string.isRequired,
// };
export default EventsInfoPage;
