import { useEffect, useState } from "react";
import "./Navbar.css"
import { Link } from "react-router-dom";
import {NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import oEyeLogo from "../Assets/eye-open.png"
import supabase from "../supabase";
import cEyeButton from "../Assets/eye-close.png"
import greentick from "../Assets/Green-tick.png"
import wrongtick from "../Assets/wrong-tick.png"
import * as Scroll from "react-scroll";
import { Link as ScrollLink } from "react-scroll";
import JSConfetti from 'js-confetti'
import Confetti from 'react-confetti'
import { Link as Link2 } from "react-scroll";


let n=1;
const RAjuuu=()=>{
// const canvas = document.getElementById('')
const jsConfetti = new JSConfetti()
// jsConfetti.addConfetti({
//   // emojis: ['🪅', '⚡️'],
//   // confettiRadius: 6,
//   // confettiNumber: 500,
// })
jsConfetti.addConfetti({
  confettiColors: [
    '#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7',
  ],
})
n+=1;
if(n===50){
  return;
}
setTimeout(() => {
  RAjuuu();
}, 10000);

}
// const abcde =async ()=>{
//   for (let i = 0; i >-1; i++) {

// }
//   }

const Navbar = () => {
  RAjuuu();


  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    setTimeout(() => LoaderLoding(selector), 2300);
  };
  const LoaderLoding=async (selector) => {
    await scroller.scrollTo(selector, {
      duration: 0,
      smooth: true,
      offset: 0,
      spy: true
    });
  };
  const [checkpassword,setcheckpassword]=useState("");
  const [password,setpassword]=useState("");
  const [email,setemail]=useState("");
  const [errors,seterrors]=useState("");
  const [errorimg,seterrorimg]=useState();
  const [error1,seterror1]=useState("");
  const [passwordVisible,setpasswordVisible]=useState(false);
  const [userEmail, setUserEmail] = useState(null);
  let popup = document.getElementById("popup");

  function openPopup() {
      popup.classList.add("open-popup");
  }
  
  function closePopup() {
      popup.classList.remove("open-popup");
  }
  // useEffect(()=>{

  //   function LoginButton(){
  //   }
  // },[])

  const eyeButtonClick = () => {
    
    setpasswordVisible((prevVisible) => !prevVisible);
  };
  const closeButtonClick = () => {
    document.getElementsByClassName("login-box")[0].style.visibility="hidden";
    document.getElementsByClassName("signup-box")[0].style.visibility="hidden";
  };

const forgotpassword=async(e)=>{
  e.preventDefault();
    let { data, error } = await supabase.auth.resetPasswordForEmail(email)
    if(error){
      seterror1("Error");
      seterrors(error.message);
      seterrorimg(wrongtick);
      openPopup()
      // alert(error.message);
    
    }
    else{
      
seterror1("Reset Password link sent successfully");
seterrors("Check Your mail for Recovery link!");
seterrorimg(greentick);
openPopup()
    // alert("Check your email for verification link!");
    closeButtonClick();
    }
}

  const signup=async(e)=>{
    e.preventDefault();
    if(checkpassword===password){
    let { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    })
    if(error){
      seterror1("Error");
      seterrors(error.message);
      seterrorimg(wrongtick);
      openPopup()
      // alert(error.message);
    }
    else{
seterror1("Verification Link Sent!");
seterrors("Check your email for verification link!( make sure to check your spam folder for email )");
seterrorimg(greentick);
openPopup()
    // alert("Check your email for verification link!");
    closeButtonClick();
    }}
    else{
      seterror1("Passwords does not match");
seterrors("");
seterrorimg(wrongtick);
openPopup()
    }
  }

  const logout=async(e)=>{
let { error } = await supabase.auth.signOut()
if(error){
  seterror1("Error");
  seterrors(error.message);
  seterrorimg(wrongtick);
  openPopup();
}
else{
  seterror1("Signout Successful");
  seterrors("");
  seterrorimg(greentick);
  openPopup()
}
  }
  const signin=async(e)=>{
    e.preventDefault();
    try{
    let { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password
    });
    if (error) {
      seterror1("Error");
      seterrors(error.message);
      seterrorimg(wrongtick);
      openPopup()
      console.log(error);
      // alert(error.message);
    }
    else{
      seterror1("Login Successful");
      seterrors("");
      seterrorimg(greentick);
      openPopup()
      // alert("Login Successful");
      closeButtonClick();
    }
  }
  catch(error){
    seterror1("Error");
    seterrors(error.message);
    seterrorimg(wrongtick);
    openPopup()
    // alert(error.message);
    console.log(error.message);
  }
  }
  useEffect(()=>{
    const checkAuthState = async (session) => {
      if (session?.user) {
        setUserEmail(session.user.email);
        document.getElementsByClassName("login-box")[0].style.visibility="hidden";
        document.getElementsByClassName("signup-box")[0].style.visibility="hidden";
      } else {
        setUserEmail(null);
      }
    };

    // // Check authentication state on component mount
    // checkAuthState();

    const authListener = supabase.auth.onAuthStateChange((_, session) => {
      checkAuthState(session);
    });

    // Cleanup subscription on component unmount
    // return () => authListener.data.unsubscribe();
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        const newPassword = prompt("What would you like your new password to be?");
        const { data, error } = await supabase.auth
          .updateUser({ password: newPassword })
 
        if (error){
          seterror1("Error");
          seterrors(error.message);
          seterrorimg(wrongtick);
          openPopup()
          // alert("There was an error updating your password.")
        }
        if (data){
          seterror1("Success");
          seterrors("Password updated successfully!");
          seterrorimg(greentick);
          openPopup()
          // alert("Password updated successfully!")
        } 
      }
    })

    },[])
  return (
    <>
      <header>
        <a href="/#Home"><h1 className="logo-top">TeSla</h1></a>
        <input type="checkbox"  id='check'/>
        <label htmlFor="check" className='menu-icon'>
        <i className="fa fa-bars menu " aria-hidden="true" id='menu'></i>
        <i className="fa fa-times" aria-hidden="true"id='close'></i>
        </label>
        
        <div className="navigation">
          <li onClick={()=>{document.getElementsByClassName("Results")[0].style.display="flex";RAjuuu();}}>Results</li>
        {location.length === 0 ? (
            <>
                <ScrollLink to="home" spy={true} smooth={true} offset={0} duration={500}  className="scroll-link"  style={{ '--i': '1' }}>
                  Home
                </ScrollLink>

                <ScrollLink to="Events" spy={true} smooth={true} offset={0} duration={500} className="scroll-link"  style={{ '--i': '2' }}>
                  Events
                </ScrollLink>
            </>
          ) : (
            <>
              <li onClick={() => goToPageAndScroll("home")} className="scroll-link"  style={{ '--i': '1' }}>
                Home
              </li>
              <li onClick={() => goToPageAndScroll("Events")} className="scroll-link"  style={{ '--i': '2' }}>
                Events
              </li>
            </>
          )}

          <Link to="/OurTeam"  style={{ '--i': '3'}}  >Our Team</Link>
          {userEmail ? (
            <li  style={{ '--i': '4' }} className="user-email scroll-link" >{userEmail}
          <div className="ProfileMenu">
          <ul>
            <li onClick={logout} ><i className="fa-solid fa-right-from-bracket scroll-link"></i> Logout</li>
          </ul>
        </div>
            </li>
          ) : (
          <li className="scroll-link" onClick={()=>{document.getElementsByClassName("login-box")[0].style.visibility="visible";document.getElementsByClassName("signup-box")[0].style.visibility="hidden"; try{    document.getElementsByClassName("register-box-1")[0].style.visibility="hidden";}catch{}}} style={{ '--i': '4' }}>Login</li>
          )}
        </div>
      </header>

      <form className="login-box" style={{"visibility": "hidden"}}>
      {/* <h1>Login</h1> */}
      <i onClick={closeButtonClick} className="fa-regular fa-circle-xmark close-popup"></i>
        <div className="username-box">
            <input  onChange={(event)=>{setemail(event.target.value)}}  type="email" placeholder="Email" id="username" required/>
            </div>
            <div className="password-box">
                <input onChange={(event)=>{setpassword(event.target.value)}} type={passwordVisible ? "text" : "password"} placeholder="password" id="password"  />
                <img onClick={eyeButtonClick} src={passwordVisible ?  oEyeLogo : cEyeButton} id="eye" />
            </div>
            <button className="login-btn" onClick={signin}>Login</button>
            <a onClick={forgotpassword} >Fogot Password?</a>
            <p>don't have a account? <a onClick={()=>{document.getElementsByClassName("login-box")[0].style.visibility="hidden";document.getElementsByClassName("signup-box")[0].style.visibility="visible";}} style={{"cursor":"pointer"}} a>Register</a></p>
        </form>

        <form className="signup-box" style={{"visibility": "hidden"}}>
        <i onClick={closeButtonClick} className="fa-regular fa-circle-xmark close-popup"></i>
        <div className="username-box">
            <input  onChange={(event)=>{setemail(event.target.value)}}  type="text" placeholder="Email" id="username1" className="TextArea" required/>
            </div>
            <div className="password-box">
                <input onChange={(event)=>{setpassword(event.target.value)}} type={passwordVisible ? "text" : "password"} placeholder="New Password" id="password1" className="TextArea" required/>
               
            </div>
            <div className="password-box">
                <input onChange={(event)=>{setcheckpassword(event.target.value)}} type={passwordVisible ? "text" : "password"} placeholder="Re-enter Password" id="passwor" className="TextArea" required/>
                <img onClick={eyeButtonClick} src={passwordVisible ?  oEyeLogo : cEyeButton} id="eye" />
            </div>
            <button type="submit" className="login-btn" onClick={signup}>Sign Up</button>
        </form>

        <div className="popup" id="popup">
            <img src={errorimg} alt="Green tick"/>
            <h2>{error1}</h2>
            <p>{errors}</p>
            <button className="ok-btn" onClick={closePopup}>OK</button>
        </div>
        <Confetti
      width={window.innerWidth}
      height={window.innerHeight}
    />
        <div className="Results" >

          <div className="Results-box">
          <Confetti
      width={window.innerWidth*0.9}
      height={ window.innerHeight*0.7}
    />
          <div className="Result-top">
          <h1>Results</h1>
          {/* <h3>We would like to take a moment, to appreciate the enthusiam and hardwork shown by each department without which this event would have not been possible! Kuddos</h3> */}
          <h3>A heartfelt salute to the unwavering enthusiasm and relentless hard work of each department—this event would simply not have been possible without your e&#120013;ceptional dedication! Kudos to all! </h3>

          {/* <h3>Kuddos, to all department. Hope you had great time</h3> */}
          {/* <h3>Secondly, apology for any lows during the event</h3> */}
          {/* <h3>Secondly, we extend our sincere appreciation for your patience and understanding during the event. In the spirit of continuous improvement, we apologize for any inconveniences that may have occurred and thank you for your cooperation.</h3> */}
          </div>
          <i onClick={()=>{document.getElementsByClassName("Results")[0].style.display="none";}} class="fa-solid fa-circle-xmark closing-symbol"></i>
          {/* <ul>
            <h2>Overall</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CSE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">50</div></li>
            </ol>
          </ul> */}
          <ul>
            <h2>Group Dance</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">500</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">300</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">100</div></li>
            </ol>
          </ul>
           <ul>
            <h2>Duo Dance</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>ECE<div className="points">50</div></li>
            </ol>
          </ul>
          <ul>
            <h2>Solo Dance</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>ECE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>EEE<div className="points">50</div></li>
            </ol>
          </ul>
          <ul>
            <h2>Tug of War</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">200</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>ECE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">50</div></li>
            </ol>
          </ul>
          <ul>
            <h2>Duo Singing</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>EEE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>ECE<div className="points">50</div></li>
            </ol>
          </ul>

          <ul>
            <h2>Solo Singing</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">350</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">250</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>EEE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CSE<div className="points">100</div></li>
            </ol>
          </ul>
            <ul>
            <h2>Surprise Events</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE/ECE<div className="points">250</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">200</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">100</div></li>
            {/* <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">50</div></li> */}
            </ol>
          </ul>

            <ul>
            <h2>Instrument</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>ECE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">50</div></li>
            </ol>
          </ul>

          <ul>
            <h2>Fashion Show</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">50</div></li>
            </ol>
          </ul>

          <ul>
            <h2>Skit</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE/CVE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CSE<div className="points">100</div></li>
            {/* <li><i class="fa-solid fa-award trophy"></i>CSE<div className="points">50</div></li> */}
            </ol>
          </ul>
          <ul>
            <h2>Face Painting</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">350</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE/CSE<div className="points">250</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">150</div></li>
            {/* <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">100</div></li> */}
            </ol>
          </ul>
          <ul>
            <h2>Binge Eating</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">250</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">200</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">50</div></li>
            </ol>
          </ul>
          <ul>
            <h2>Game of Tag</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">350</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">250</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>EEE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">100</div></li>
            </ol>
          </ul>
          <ul>
            <h2>5 Men Race</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">200</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>ECE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>EEE<div className="points">50</div></li>
            </ol>
          </ul>
          <ul>
            <h2>Bo&#120013; Cricket</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE/ECE<div className="points">400</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE/CSE<div className="points">200</div></li>
            {/* <li><i class="fa-solid fa-award trophy"></i><div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i><div className="points">50</div></li> */}
            </ol>
          </ul>
          <ul>
            <h2>Stand Up</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">350</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>CSE<div className="points">250</div></li>
            <li><i class="fa-solid fa-award trophy"></i>MCE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">150</div></li>
            </ol>
          </ul>
          <ul>
            <h2>BGMI</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>CVE<div className="points">350</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">250</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">200</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CSE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>EEE<div className="points">100</div></li>
            </ol>
          </ul>
          <ul>
            <h2>Quiz</h2>
            <ol>
            <li><i class="fa-solid fa-trophy trophy"></i>EEE<div className="points">300</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>ECE<div className="points">200</div></li>
            <li><i class="fa-solid fa-trophy trophy"></i>MCE<div className="points">150</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CSE<div className="points">100</div></li>
            <li><i class="fa-solid fa-award trophy"></i>CVE<div className="points">50</div></li>
            </ol>
          </ul>
          </div>
        </div>
    </>
  );
}

export default Navbar;
