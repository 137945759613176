import { createRoot } from "react-dom/client";
import React from "react";
import "./index.css";
import ErrorPage from "./error-page";
import App from "./App";
import Scrolltotop from "./Components/Scrolltotop";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import EventsInfoPage from "./Components/EventsInfoPage";
import ComingSoon from "./Components/ComingSoon";
import OurTeam from "./Components/OurTeam";

const root = createRoot(document.getElementById("root"));

const AppRoutes = () => (
  <Routes >
    <Route path="/" element={<App />}  />
    <Route path="/ComingSoon" element={<ComingSoon />} />
    <Route path="/OurTeam" element={<OurTeam />} />
    <Route
      path="/Quizzix"
      element={
        <EventsInfoPage
          eventName="Quizzix"
          eventImgSrc="https://i.postimg.cc/tTG6Z62L/qw-ele-7.webp"
          eventLink="Quizzix"
          eventRules="&#9758; Points for this event:- 300-200-150-100-50
&#9758; Quiz will be conducted in two rounds:- Preliminary and Finale.
&#10148; The Preliminary Round will be a ‘Pen and Paper’ Round.
&#10148; 4 Top Scoring Teams will qualify for the next round.
&#10148; The Round structure of the quiz will be elaborated upon at the event.
&#10148; Profanity in any form is not allowed and players are advised to be mindful of that.
&#10148; The use of unfair means will lead to immediate disqualification of the team from this event.
&#10148; Quizmaster’s decisions are final and binding in all possible scenarios.
CONTACT INFO :
Gunaditya Patil - +91 70660 57919
Arpita Sukhwal - +91 86963 86788"
          eventPrize=""
          eventFile=""
        />
      }
    />
    <Route
      path="/SurpiseEvent"
      element={
        <EventsInfoPage
          eventName="Surpise Event"
          eventImgSrc="https://i.postimg.cc/SxvXk9px/17973871.jpg"
          eventLink="SurpiseEvent"
          eventRules="&#9758; Points Distribution: 250-200-150-100-50
Surprise 
&#9758; Theme: Mainly Technical
"
          eventPrize=""
          eventFile=""
        />
      }
    />
    <Route
      path="/TreasureHunt"
      element={
        <EventsInfoPage
          eventName="Treasure Hunt"
          eventImgSrc="https://i.postimg.cc/Gm2LCHh1/1532464445175.jpg"
          eventLink="TreasureHunt"
          eventRules="Will be reveled on the spot
Savvy?"
          eventPrize="Points Distribution: 400-300-200-100-50"
          eventFile=""
        />
      }
    />

<Route
      path="/EnergeticEuphoriaExpress"
      element={
        <EventsInfoPage
          eventName="Energetic Euphoria E&#120091;press (Group Dance)"
          eventImgSrc="https://www.stgpresents.org/images/education/Dance-This/dt26.jpg"
          eventLink="EnergeticEuphoriaExpress"
eventRules="
&#187;&#187;&#187;&#187; Group Dance &#171;&#171;&#171;&#171;
&#9758; Points for this event:- 500-400-300-200-100
&#10148; A requisite minimum of four participants must be present on the stage at all times during the event.
&#10148; Only individuals officially declared as part of the designated squad are permitted on the stage during the event.
&#10148; Squad member’s list of each department should be given to the organizing team two days prior to the event.
&#10148; The total duration for each performance must adhere to a specified time frame of 8 to 10 minutes.
&#10148; In the event of technical difficulties or unforeseen issues, a retrial is allowed. This provision is aimed at ensuring fair opportunities for all participants.
&#10148; No unauthorized person is allowed access to the stage area.
&#10148; Vulgarity and obscenity are left to the organizers’ discretion. It may lead to disqualification(Songs are to be chosen accordingly).
&#10148; The decision taken by the judges will be final and binding.
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds.
&#10148; No hazardous chemicals or flammable stuff to be used.
&#10148; Intoxication of any form will lead to disqualification.
&#10148; Participants have to arrange accessories, Costumes and props by themselves.
&#10148; All the props that the group is going to use should be informed to the organizers in advance.
&#10148; Song tracks are to be submitted to the organisers atleast 2 days prior to the event.
&#187;&#187;&#187;&#187; Duet Dance &#171;&#171;&#171;&#171;
&#9758; Points for this event:- 400-300-200-100-50
&#9758; Team Composition: 2 members from each department
&#9758; Duration: 4-6 min
&#10148; In the event of technical difficulties impeding the performance, participants are entitled to request a retrial.
&#10148; The decision taken by the judges will be final and binding.
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
&#10148; Song tracks are to be submitted to the organizers atleast 2 days prior to the event.
&#187;&#187;&#187;&#187; Solo Dance &#171;&#171;&#171;&#171;
&#9758; Points for this event:- 400-300-200-100-50
&#9758; Team Composition: 1 members from each department
&#9758; Duration:- 3-6 minutes
&#10148; Participants are permitted to have a designated helper who is not allowed on stage but may assist with preparations and technical support backstage
&#10148; In the event of technical difficulties impeding the performance, participants are entitled to request a retrial.
&#10148; The decision taken by the judges will be final and binding.
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
&#10148; Song tracks are to be submitted to the organisers atleast 2 days prior to theevent.
"
          eventPrize="Three Events within this (Group + Duet + Solo)."
          eventFile=""
        />
      }
    ></Route>
    {/* <Route
      path="/EnergeticEuphoriaExpressDuetDance"
      element={
        <EventsInfoPage
          eventName="Energetic Euphoria Express (Group Dance)"
          eventImgSrc="https://www.stgpresents.org/images/education/Dance-This/dt26.jpg"
          eventLink="Group Dance"
eventRules="
- A requisite minimum of four participants must be present on the stage at all times during the event.
- Only individuals officially declared as part of the designated squad are permitted on the stage during the event.
- Squad member’s list of each department should be given to the organizing team two days prior to the event.
- The total duration for each performance must adhere to a specified time frame of 8 to 10 minutes.
- In the event of technical difficulties or unforeseen issues, a retrial is allowed. This provision is aimed at ensuring fair opportunities for all participants.
- No unauthorized person is allowed access to the stage area.
- Vulgarity and obscenity are left to the organizers’ discretion. It may lead to disqualification(Songs are to be chosen accordingly).
- The decision taken by the judges will be final and binding.
- If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds.
- No hazardous chemicals or flammable stuff to be used.
- Intoxication of any form will lead to disqualification.
- Participants have to arrange accessories, Costumes and props by themselves.
- All the props that the group is going to use should be informed to the organizers in advance.
- Song tracks are to be submitted to the organisers atleast 2 days prior to the event.
.
>>DUET
>>Points
>>Team Composition: 2 members from each department
>>Duration: 4-6 min
- In the event of technical difficulties impeding the performance, participants are entitled to request a retrial.
- The decision taken by the judges will be final and binding.
- If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
- Song tracks are to be submitted to the organizers atleast 2 days prior to the event.

>>SOLO
>>Points Distribution:- 
- Participants are permitted to have a designated helper who is not allowed on stage but may assist with preparations and technical support backstage
- In the event of technical difficulties impeding the performance, participants are entitled to request a retrial.
- The decision taken by the judges will be final and binding..
- If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
- Song tracks are to be submitted to the organisers atleast 2 days prior to theevent.
"
          eventPrize="Total three Events within this."
          eventFile=""
        />
      }
    ></Route>
    <Route
      path="/EnergeticEuphoriaExpressGroupDance"
      element={
        <EventsInfoPage
          eventName="Energetic Euphoria Express (Group Dance)"
          eventImgSrc="https://www.stgpresents.org/images/education/Dance-This/dt26.jpg"
          eventLink="Group Dance"
eventRules="
>>SOLO
- Participants are permitted to have a designated helper who is not allowed on stage but may assist with preparations and technical support backstage
- In the event of technical difficulties impeding the performance, participants are entitled to request a retrial.
- The decision taken by the judges will be final and binding.
- If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
- Song tracks are to be submitted to the organisers atleast 2 days prior to theevent.
"
          eventPrize="Points Distribution:- 500-400-300-200-100"
          eventFile=""
        />
      }
    ></Route> */}
    
    <Route
      path="/TheVocalNirvana"
      element={
        <EventsInfoPage
          eventName="The Vocal Nirvana (Singing Solo + Duet + Instruments )"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/8/87/Roxette_at_the_Beacon%2C_September_2012.jpg"
          eventLink="TheVocalNirvana"
eventRules="&#10146; VOCAL NIRVANA consists of 3 categories :
&#9758; SOLO SENSATION:-Solo singing competition.
Points Distribution:- 350-250-200-150-100
&#9758; DYNAMIC DUO :- duet singing competition.
Points Distribution:- 400-300-200-100-50
&#9758; ROCKIN’ BAND :- Battle of bands.
Points Distribution:- 400-300-200-100-50
&#187;&#187;&#187;&#187; SOLO SENSATION &#171;&#171;&#171;&#171;
&#10148; This is a solo singing competition.
&#10148; A maximum of 1 participants per department is allowed.
&#10148; The final performance should not exceed 5 minutes (minimum 3)
&#10148; Use of karaoke is allowed (No instruments are to be used in this round).
&#10148; In case of a karaoke the track has to be be duly submitted to the organizers.
&#10148; The decision taken by the judges will be final and binding.
&#10148; Extra points for originality will be awarded.
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
&#187;&#187;&#187;&#187; THE DYNAMIC DUO &#171;&#171;&#171;&#171;
&#10148; This is a duet singing competition.
&#10148; A maximum of 2 participants per department are allowed
&#10148; The final performance should not exceed 7 minutes. (5 minutes minimum).
&#10148; Both the participants will have to sing.
&#10148; Either instrument or karaoke can be used.
&#10148; In the case of karaoke the track has to be duly submitted to the organizers.
&#10148; The decision taken by the judges will be final and binding.
&#10148; Extra points for originality will be awarded.
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
&#187;&#187;&#187;&#187; THE ROCKIN’ BAND( INSTRUMENTS )  &#171;&#171;&#171;&#171;
&#10148; Team Composition:- 3 members from each department should form a team
&#10148; Singing of any form is not allowed
&#10148; The instrument is to be created from scrap. No pre existing instruments are to be used.
&#10148; Minimum time is 3 minutes, It should not exceed 5 minutes.
&#10148; Beatboxing is allowed.
&#10148; The decision taken by the judges will be final and binding..
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
"
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>

    <Route path="/BoxCricket" element={
    <EventsInfoPage
      eventName="Bo&#120013; Cricket"
      eventImgSrc="https://media.hudle.in/venues/b11383c4-36e2-48f0-8b19-e6e7c1b77d55/photo/663934d9ca9699e474f795d2b77b956a59377c0e"
      eventLink="BoxCricket"
      eventRules="
&#9758; Team Format: Each team shall be represented by 6 players with 1 substitute ( to be declared )
&#9758; Points for this event :- 400-300-200-100-50
&#10148; A total 4 overs will be played in one innings.
&#10148; In between the innings, 1 magic over will be there, in which if a batsman hits six, they get double runs which means 6 runs will get 12 runs means hitting any run get double, but not wicket will not get double.
&#10148; 1 tip to the boundary will be considered 6 runs, while multiple tips will be considered 4 runs.
&#10148; If a batsman hits directly to the boundary line or outside the boundary, they will get out.
&#10148; Both Side of stumps one box will be there, in that only bowler have to stand and deliver the ball.
&#10148; Examples like a bowler takes the ball in the box they don't have to stretch their hand besides the earth throw the ball not pull the leg and not shake.
&#10148; Spin of the ball is not allowed.
&#10148; Practical will be shown by the umpire or the organizers before the innings or between the overs.
&#10148; A toy stand(in-flit table toy) 3 pieces will be placed in the ground so that if a batsman hits to the toy penalty of 3run will be given and if in magic overs it will double the penalty
&#10148; Usual rules of cricket will be there.
&#10148; The format will be a Round Robin group stage competition with the top 2 teams qualifying for the finals, recorded points table will be there.
&#10148; WIN – 2 Points, LOSE – 0, tie super over will be decided.
&#10148; The umpires’ decision will be binding andfinal. Other extra rules will be told by umpires and organizers.
&#10148; All players must report at the venue 10-15 minutes before the game starts.
&#10148; Matches will be played with soft tennis ball;players are requested to come in sportswear.
&#10148; All players are expected to be courteous to each other and with the officials
&#10148; Arguments with the umpire will lead to player/team disqualification. This will be strictly followed.
"
      eventPrize=""
      eventFile=""
    ></EventsInfoPage>} />
        <Route
      path="/5MenlegRace"
      element={
        <EventsInfoPage
          eventName="5 Men leg Race/Women race"
          eventImgSrc="https://i.postimg.cc/cHb8MQbX/Harry-KH-230510-Newport-284-A8849.jpg"
          eventLink="LegRace"
          eventRules="
&#9758; Venue: Campus Ground
&#9758; Points for this event - 300-200-150-100-50
&#10148; Each department shall be represented by 1 team consisting of 3 boys and 2 girls.
&#10148; 2 Replacement of 1 boy -1 girl (Should be submitted to the organizers beforehand)
&#10148; A 100 m race will be there

 "
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>
            <Route
      path="/BGMI"
      element={
        <EventsInfoPage
          eventName="BGMI"
          eventImgSrc="https://i.postimg.cc/WpCcsjTH/Whats-App-Image-2024-02-07-at-10-28-46.jpg"
          eventLink="BGMI"
          eventRules="
&#9758; Points for this event :- 350-250-200-150-100
&#9758; Team Composition: Each team is represented by 4 players (1 substitute to be declared)
&#10148; Match will be played on livic mode
&#10148; Only smartphones are allowed
&#10148; Use of tablets/iPads is prohibited
&#10148; 3 matches will be conducted
&#10148; The combined scores of 3 matches will be considered for the final score
&#10148; The scores for 1st, 2nd and 3rd positions in each match will be 10, 7 and 5 respectively
&#10148; In last round points will be 1.5x.
 "
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>
    <Route
      path="/GameofTag"
      element={
        <EventsInfoPage
          eventName="Game of Tag"
          eventImgSrc="https://global-uploads.webflow.com/60dfb7bd2f9507f7616a7cac/60e11d929d6c02ebfd8b62ae_41-DSC02043.jpg"
          eventLink="GameofTag"
          eventRules="
&#9758; Team Composition: 1 boy and 1 girl from each branch
&#9758; Points for this event :- 350-250-200-150-100
&#10148; Each team shall be represented by 2 students– 1 boy and 1 girl.
&#10148; There can be two injury substitutes– 1 boy and 1 girl.
&#10148; Each round will consist of 1 minute chase and 1 minute evade.
&#10148; The format will be a Round Robin group stage competition with the top 2 teams qualifying for the finals.
&#10148; The Final will consist of 2 one minute chases and 2 one minute evades (alternate)."
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>
        <Route
      path="/TugofWar"
      element={
        <EventsInfoPage
          eventName="Tug of War"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a3/Irish_600kg_euro_chap_2009_%28cropped%29.JPG"
          eventLink="TugofWar"
eventRules="
&#9758; Venue: Campus Ground
&#9758; Points for this event - 300-200-150-100-50
&#10148; Each department shall be represented by 1 team consisting of 8 boys and 4 girls.
&#10148; 3 Replacement (Should be submitted to the organizers before hand)
&#10148; The format will be a Round Robin group stage competition with the top 2 teams qualifying for the finals.
&#10148; Spikes not allowed (Disqualified if caught)
&#10148; Over shoulder pull will results in penalty
&#10148; Best of three tugs will be finalized for final tug
"
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>


    {/* <Route
      path="/BeatBattleBonanza"
      element={
        <EventsInfoPage
          eventName="Beat Battle Bonanza (Dance Battle)"
          eventImgSrc="https://live.staticflickr.com/65535/40820359833_08709cae31_b.jpg"
          eventLink=""
          eventRules="
- In the event of technical difficulties impeding the performance, participants are entitled to request a retrial.
- The decision taken by the judges will be final and binding.
- If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds.
- Song tracks are to be submitted to the organizers atleast 2 days prior to the event."
          eventPrize=""
          eventFile=""
        />
      }
    ></Route> */}

    <Route
      path="/NukkadNatak"
      element={
        <EventsInfoPage
          eventName="Nukkad Natak (Skit)"
          eventImgSrc="https://i.postimg.cc/mB0fS2S8/maharashtrachi-hasyajatra-returns.jpg"
          eventLink="NukkadNatak"
          eventRules="
&#9758; Team Composition: 5 minimum. No maximum limit
&#9758; THEME: Open Theme
&#10148; Only one team per department. Minimum time is 5 mins. Maximum time is 15 mins.
&#10148; The team will be alerted 2.5 minutes before the maximum time limit.
&#10148; Vulgarity of any sense is not allowed.
&#10148; Street Play must not offend any religion or profession.
&#10148; Any political party or political leader should not be targeted.
&#10148; No pre-recorded music will be allowed.
&#10148; Participants should bring their props.
&#10148; Rehearsal before the event on the field will not be allowed.
&#10148; The decisions of the judges will be final, beyond dispute and binding on all the teams
&#10148; If the time limit exceeds, there will be a deduction of 5% score for every 10 seconds
"
          eventPrize=">>Points for this event:- 400-300-200-100-50"
        />
      }
    ></Route>
<Route
      path="/JokesJubilation"
      element={
<EventsInfoPage
          eventName="Jokes Jubilation (Stand up)"
          eventImgSrc="https://i.postimg.cc/jSwSm8Fm/294652931-384864723772607-6005536053070374275-n.jpg"
          eventLink="JokesJubilation"
          eventRules="
&#9758; Team Composition: 6-10
&#9758; Duration: 10 mins maximum (Including setup and the performance both).
&#9758; THEME: Open Theme
&#9758; Points for this event:- 350-300-250-200-150
&#10148; Each branch is allowed one entry.
&#10148; Participants must perform original stand-up comedy material.
&#10148; The contestant with the highest score will be considered as winner.
&#10148; Content must be appropriate for a diverse audience ( no offensive, discriminatory or inappropriate material).
&#10148; On stage References and Notes/chits will be fined penalty.
&#10148; Violation of rules may lead to immediate disqualification.
&#10148; The decision of the judges will be considered as the final decision.
"
          eventPrize=""
          eventFile=""
        />
      }
></Route>
    <Route
      path="/RainbowPalette"
      element={
        <EventsInfoPage
          eventName="Rainbow Palette(Face painting)"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/7/7e/Self_traditional_folk_face_painting_%282%29.jpg"
          eventLink="Facepainting"
          eventRules="
&#9758; Team Composition: 2 members (Painter and the Model)
&#9758; Duration: 2 hour
&#9758; THEME: Cartoon/ Horror
&#9758; Points for this event: - 350-250-200-150-100
General Rules and Conditions to be followed:
1. Each competitor must supply all of his or her face painting supplies
2. Competitors may not share paints or other supplies during competition.
3. Competitors may not use photos or drawings to refer to while painting. All designs should be painted free-hand.
&#10174;Competitors MAY USE any or all of the following items:
&#10148; Only professional makeup (cosmetics) and paint products made specifically for the skin Products may be applied wet or dry.
&#10148; Skin-safe adhesives & adhesive removers, Glitter in loose powder, or liquid form.
&#10148; Gemstones (stones may have adhesive backing or be applied with skin-safe adhesive)
&#10148; Any other skin-friendly items.
&#10174;Competitors SHOULD NOT use:
&#10148; Stencils or stamps of any kind.
&#10148; Masks over any part of the face.
&#10148; False eyelashes, special effects contact lenses, novelty eyeglasses or sunglasses
"
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>
    <Route
      path="/TheRetailTherapy"
      element={
        <EventsInfoPage
          eventName="The Retail Therapy (Fashion show)"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Magdalena_Frackowiak.jpg/800px-Magdalena_Frackowiak.jpg"
          eventLink="Fashionshow"
          eventRules="
&#9758; Team Composition: 6-10
&#9758; Duration: 10 mins maximum (Including setup and the performance both).
&#9758; THEME:
1) Superheros and Supervillans
2) Halloween
3) Mythical Creatures
&#9758; Points for this event:- 400-300-200-100-50
&#10148; Purchased, professionally made or rented costumes are not permitted.
&#10148; Use of fire or any kind of dangerous prop that has a malfunction will lead to a point deduction.
&#10148; Teams have to bring their props and take care of the props themselves.
&#10148; Irrelevant bare-body exposure is prohibited.
&#10148; Vulgarity is strongly prohibited. Any form of obscenity will lead to disqualification of the team from the contest.
&#10148; Participants are advised to carry their tracks in a pen drive and submit it in advance.
&#10148; No introduction of the participants or story telling will be allowed. Music will be permitted.
&#10148; The decision of the judges will be final and binding.
"
          eventPrize=""
          eventFile=""
        />
      }
    ></Route>
    <Route path="/*" element={<ErrorPage />} />
    <Route
      path="/Photography"
      element={
        <EventsInfoPage
          eventName="Photography"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Winterswijk_%28NL%29%2C_Woold%2C_Boven_Slinge_--_2014_--_3170.jpg/800px-Winterswijk_%28NL%29%2C_Woold%2C_Boven_Slinge_--_2014_--_3170.jpg"
          eventLink="Photography"
          eventRules="
&#9758; Participant Eligibility: All the students of NIT GOA are eligible to participate in the photo submission activity.
&#9758; Theme: Participants must capture photos during different events of PRASANG.
&#9758; Submission Guidelines:
1. Each participant can submit one photo only
2. Photos must be original and taken by the participant during the PRASANG event.
3. Submissions should be in digital format ( a link will be provided on the day of competition).
4. Each entry must include a caption describing the photograph.
5. Photos should have a layout of 4×5.
6. Pixels of the photographs should be 1080×1350.
7. The photo must be clicked during prasang event 16-18 February
&#9758; Judging criteria:
Entries will be judged based on creativity, photographic quality, and effectiveness in conveying the uniqueness of the theme. The final score will be determined by a combination of judges’ scores (60%) and the number of likes and comments received on Instagram (40%).
"
          eventPrize="Coming soon"
          eventFile="Coming soon"
        />
      }
    />
    
        <Route
      path="/DigitalArt"
      element={
        <EventsInfoPage
          eventName="Digital Art"
          eventImgSrc="https://i.postimg.cc/Kvn1ydhc/images.jpg"
          eventLink="DigitalArt"
          eventRules="
&#9758; Eligibility: The competition is open to all interested students from NIT GOA.
&#9758; Theme Release: The title for the competition will be released on 10th February.
&#9758; Submission Guidelines
&#10148; Submission Period: The submission period for the competition is from 10th to 17th February, 2pm. Submissions received outside this period will not be considered.
&#10148; Artwork Format: The artwork should be in a 1:1 aspect ratio, with a resolution of 1080 x 1080 pixels.
&#10148; Originality: All entries must be the original work of the entrant.
&#10148; Number of Entries: Each participant is allowed to submit only 1 artwork.
&#10148; Submission Format: Artworks must be submitted in JPEG or PNG format.
&#10148; Description and Title: Each entry must include a title and a description explaining the artwork. The description should include all the software used in reating the artwork.
"
          eventPrize=""
        />
      }
    />
    <Route
      path="/BingeEating"
      element={
        <EventsInfoPage
          eventName="Binge Eating"
          eventImgSrc="https://upload.wikimedia.org/wikipedia/commons/1/1c/Eating_Comp_21aug2005.jpg"
          eventLink="BingeEating"
          eventRules="
&#9758; Team Composition: 1 participant per branch ( 1 boy + 1 girl )
&#9758; Points for this event :- 250-200-150-100-50
&#10148; Each team shall be represented by 2 students– 1 boy and 1 girl.
&#10148; There can be two injury substitutes– 1 boy and 1 girl.
&#10148; Each participant must mark the time by consuming a set amount of quantity of food
&#10148; Participants must show proof of consumption by opening their mouths, otherwise penalties will be applied (Decide on the spot)
&#10148; The participant who consumes the whole set of food in the shortest time will be marked as a winner.
"
          eventPrize=""
        />
      }
    />
    <Route path ="/account/update-password'" element={<></>}>
    </Route>
  </Routes>
  
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <Scrolltotop></Scrolltotop> */}
      <AppRoutes />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
