import React from "react";
import { Link } from "react-router-dom";
import border from "../Assets/yellow-1210520_1280.png"
const OurTeamProfile = ({profileimg,Name,position,linkedin,email,insta}) => {
  return (
    <>     
    <div className="profile-box">
      <div className="profile">
      <img className="profileborder" src={border} alt="" ></img>
        <img src={profileimg} className="profile-photo" alt={Name} />
      </div>
      <a href={linkedin} target="_blank">
          <i className="fa-brands fa-linkedin-in twitter"></i>
        </a>
        <a href={insta}  target="_blank">
          <i className="fa-brands fa-instagram insta"></i>
        </a>
        <a href={email}  target="_blank">
          <i className="fa-solid fa-envelope gmail"></i>
        </a>
      <h2>{Name}</h2>
      <h4>{position}</h4>
    </div>
    </>
  );
};

export default OurTeamProfile;
