import React, { useEffect, useRef } from "react";
import "./Prasanginfo.css";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import Oscilloscope from "../Oscilloscope"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// import { AnimationAction } from "three";
import TextPlugin from "gsap/TextPlugin";
// import Drone2 from "../Drone qui plane 1"
import Today from "../Dronehover"
// import { GSDevTools } from "gsap/all";
// import GSDevTools from "gsap/GSDevTools";
// import { GSDevTools } from "gsap/all";
// import GSDevTools from "gsap/src/GSDevTools";
const Prasanginfo = () => {
  useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".Prasang-logo", {
      scrollTrigger: { 
        trigger:".Prasang-logo",
        toggleActions: "play none none none",
        start: "top center",
        end: "+=200",
        // pin:"true",
        // scrub: 0.2
      },// start animation when ".box" enters the viewport
      x: "25%",
      rotation: 360
    });


    // gsap.to(".canvas-holder", {
    //   scrollTrigger: { 
    //     trigger:".canvas-holder",
    //     toggleActions: "play none none none",
    //     start: "top center",
    //     end: "+=300",
    //     // pin:"true",

    //     scrub: 0.2
    //   },// start animation when ".box" enters the viewport
    //   x: "-100vw",
    // });
    if (window.innerWidth >= 1024) {
      gsap.registerPlugin(TextPlugin)
      gsap.to(".Prasang-indetails", {
        scrollTrigger: { 
          trigger:".Prasang-indetails",
          toggleActions: "play pause resume none",
          start: "top center",
          end: "bottom 80vh",
          // markers:true,
          pin:".Newdiv",
          // pinSpacing:false,
          revealDelay:0.5, 
          scrub: true
        },
        duration: 5, 
        text: {
          value: "The Tesla Club of Electrical and Electronics Engineering (EEE) department at NIT Goa proudly presents Prasang, an annual extravaganza that celebrates innovation, creativity, and the spirit of engineering excellence. Prasang is more than just an event; it's a platform that brings together budding engineers, industry experts, and enthusiasts to explore, learn, and showcase their skills. Be a part of this dynamic experience, where ideas flourish, collaborations thrive, and the joy of discovery is paramount. Gear up for an unforgettable journey, and we eagerly anticipate meeting you in Prasang!",
          newClass: "orange",
          delimiter: "",
        },
        // revealDelay:0.5, 
      });
      }


    // var tl = gsap.timeline({defaults: {duration: 2, ease: "none"}});
    //  gsap.to("#newClass", {
    //   scrambleText:{
    //     text:"The Tesla Club of Electrical and Electronics Engineering (EEE) department at NIT Goa proudly presents Prasang, an annual extravaganza that celebrates innovation, creativity, and the spirit of engineering excellence. Prasang is more than just an event; it's a platform that brings together budding engineers, industry experts, and enthusiasts to explore, learn, and showcase their skills. Be a part of this dynamic experience, where ideas flourish, collaborations thrive, and the joy of discovery is paramount. Save the date, gear up for an unforgettable journey, and we eagerly anticipate meeting you in Prasang!", 
    //     chars:"lowerCase", 
    //     speed:0.3, 
    //     newClass:"orange",
    //      revealDelay:0.5, 
    //      tweenLength:false}})

// GSDevTools.create({animation: tl, minimal: true});

  },[]);




  return (
    <div id="Prasang-info-section">
      <div className="Newdiv">
        <div className="drone-holder">
        <img src="https://i.postimg.cc/wBJtv587/Prasang-Logo-1-1.png" alt="Prasang-Logo" className="Prasang-logo"/>
            <div className="drone-holder-left">
            </div>
              <div className="canvas-holder">
      <Canvas>
        <ambientLight />
        {/* <pointLight position={[10, 10, 10]} /> */}
        <OrbitControls enableZoom={false} autoRotate autoRotateSpeed={10}></OrbitControls>
        <Suspense fallback={null}>
          <Today />
        </Suspense>
        {/* <CameraControls></CameraControls> */}
        <Environment preset="sunset"></Environment>
      </Canvas>
      {/* <iframe src='https://my.spline.design/untitled-fca58aeb2f25d827a27645e64f3fd953/' frameborder='0' width='100%' height='100%'></iframe> */}
      </div>
      </div>
      <div className="PrasangInfo">
        <div className="Prasang-para center">
          <h1 className="center">About prasang!</h1>
          <h1 className="Prasang-Dates">Save the Dates 16, 17& 18 Feb.</h1>

          <p className="Prasang-indetails">
          
          {/* XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO
          XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO
          XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO
          XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOX
          OXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO
          XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO
          XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOX
          OXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO
          XOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXOXO */}
            The Tesla Club of Electrical and Electronics Engineering (EEE)
            department at NIT Goa proudly presents Prasang, an annual
            extravaganza that celebrates innovation, creativity, and the spirit
            of engineering excellence. Prasang is more than just an event; it's
            a platform that brings together budding engineers, industry experts,
            and enthusiasts to explore, learn, and showcase their skills. Be a
            part of this dynamic experience, where ideas flourish,
            collaborations thrive, and the joy of discovery is paramount. Gear up for an unforgettable journey, and we eagerly
            anticipate meeting you in Prasang!
            
          </p>

        </div>
      </div>
      <div className="Oscilloscope">
        <Canvas>
        <OrbitControls enableZoom={false}></OrbitControls>
        <ambientLight></ambientLight>
            <Suspense fallback={null}>
            <Oscilloscope/>
            </Suspense>
            <Environment preset="sunset"></Environment>
        </Canvas>
      </div>


      </div>
    </div>
  );
};

export default Prasanginfo;
